import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isChatOpen: false,
  isfirstLogin: true,
};

const name = "globalStates";

export const globalSlice = createSlice({
  name,
  initialState,
  reducers: {
    toggleChatStates: (state, action) => {
      state.isChatOpen = !state.isChatOpen;
    },
    setFirstLogin: (state, action) => {
      state.isfirstLogin = false;
    },
  },
});

export const { toggleChatStates,setFirstLogin  } = globalSlice.actions;

export default globalSlice.reducer;
