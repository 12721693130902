import * as Yup from "yup";

export const CreateNewPasswordValidationSchema = Yup.object().shape({
    new_password: Yup.string()
        .required("New Password is required")
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Password must be 8 characters long and include a mix of letters, numbers, and symbols'
        ),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        .required("Confirm Password is required"),
});
