import { Box } from "@mui/material";
import nephrolyticsloginlogo from "assets/svg/light/nephrolyticsloginlogo.svg";
import Text from "components/common/Typography/Text";

const LoginWrapper = ({ props, children }) => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100%",
        boxSizing: "border-box",
        padding: {
          xs: "2% auto",
          sm: "2% 6%",
          md: "2% 6%",
          lg: "2% 6%",
          xl: "2% 6%",
          xxl: "5% 6%",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        background: "rgba(255, 255, 255, 0.3)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "24px",
          background: "rgba(255, 255, 255, 0.60)",
          width: "100%",
          height: "100%",
          padding: {
            xs: "auto",
            sm: "4% 0%",
            md: "4% 0%",
            lg: "3% 0%",
            xl: "3% 0",
            xxl: "7% 0",
          },
        }}
      >
        <Box>
          <img
            src={nephrolyticsloginlogo}
            width={"349px"}
            height={"59px"}
            alt="Nephrolytics Logo"
          />
        </Box>
        {children}
      </Box>
      <Text
        sx={{ color: "#555555" }}
        id={"copyright-text"}
        center
        marginY={2}
        variant="propertyLabel"
      >
        Copyright © 2024{" "}
        <a
          href="https://nephrolytics.ai/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="privacyPolicy"
          style={{
            fontSize: "11px",
            textDecoration: "none",
            color: "#246efd",
            fontWeight: "600",
          }}
        >
          Privacy Policy
        </a>{" "}
        All rights reserved.{" "}
        <a
          href="https://nephrolytics.ai/terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="termsOfService"
          style={{
            fontSize: "11px",
            textDecoration: "none",
            color: "#246efd",
            fontWeight: "600",
          }}
        >
          Terms of Service
        </a>
      </Text>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "300px",
          maxWidth: "100%",
        }}
      >
        <Box></Box>
        <Box></Box>
      </Box>
    </Box>
  );
};

export default LoginWrapper;
