import { Divider } from "@mui/material";
import Text from "components/common/Typography/Text";
import { memo, useContext, useEffect, useState } from "react";
import { SubForm } from "../common/SubForm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import { deletePharmacy, fetchAllPharmacies } from "apiClients/pharmacies";
import CustomModal from "components/common/Modal/CustomModal";
import PharmacyModal from "../../components/PharmacyModal";

const pharmacyInputs = [
    {
        label: "Name",
        key: "pharmacy_name",
        cols: 6,
        readonly: true,
    },
    {
        label: "Address",
        key: "address",
        cols: 6,
        readonly: true,
    },
];

export const PharmacyReview = memo(() => {
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();
    const { registerForm, patientId } = useContext(IntakeFormContext);

    const { data, isLoading, refetch } = useQuery({
        queryKey: ["pharmacies", patientId],
        queryFn: async () => await fetchAllPharmacies(patientId),
    });

    // Currently only need delete logic since adding will be done in the modal.
    const mutation = useMutation({
        mutationFn: async (dirtyValue) => {
            if (dirtyValue.id) {
                if (dirtyValue.delete) {
                    // If delete is set we need to delete the pharmacy.
                    return deletePharmacy(patientId, dirtyValue.id);
                }
            }
        },

        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["pharmacies", patientId]
            });
        },
    });

    useEffect(() => {
        return () => {
            queryClient.removeQueries({
                queryKey: ["pharmacies", patientId]
            });
        }
    }, [queryClient, patientId]);

    const handleSubmit = async (dirtyValues) => {
        const operations = dirtyValues.map((dirtyValue) => {
            return mutation.mutateAsync(dirtyValue);
        });
        return Promise.allSettled(operations);
    };


    useEffect(() => {
        registerForm('pharmacies', handleSubmit, data?.results);
    }, [data]);

    const onClose = () => {
        setOpen(false);
        refetch();
    }

    return <>
        <Text variant="h1">Pharmacy Review</Text>

        <Divider sx={{ my: 2 }} />

        <SubForm formKey="pharmacies" subSections={pharmacyInputs} dataLoading={isLoading} data={data?.results} onAdd={() => setOpen(true)} />

        {open && <CustomModal
            fullWidth
            maxWidth="md"
            setOpen={onClose}
            open={open}
            title="Add Pharmacy"
        >
            <PharmacyModal setIsOpenModal={onClose} />
        </CustomModal>}
    </>
});
