export const LiveTranscriptWrapper = {
  border: "1px solid #E1E1E1",
  backgroundColor: "#F8F4FF",
  borderRadius: "12px",
  height: "150px",
  overflowY: "auto",
  marginTop: "20px",
  paddingLeft: "7px",
};
export let stripStyling = {
  backgroundColor: "#E2E2E2",
  cursor: "pointer",
  color: "#272A40",
  minWidth: "100%",
  height: "auto",
  fontSize: "11px",
  lineHeight: "13px",
  maxHeight: "max-content",
  fontWeight: "700",
  borderRadius: "30px",
  display: "flex",
  padding: "10px",
  justifyContent: "space-around",
  alignItems: "center",
  marginBottom: "6px",
};
