import { makeStyles } from "@mui/styles";

export const useNotesHistoryModalStyles = makeStyles(() => ({
  accordionRoot: {
    marginBottom: "10px",
    borderRadius: "0px !important",
    "&::before": {
      height: "0px !important",
    },
    "& .MuiAccordionSummary-root ": {
      backgroundColor: "#E5EBFF",
      boxShadow: "none",
      borderRadius: "8px",
      minHeight: "36px !important",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      color: "#2D3748",
    },
    "& .MuiButtonBase-root.MuiTab-root": {
      alignItems: "flex-start",
      textTransform: "capitalize",
      marginBottom: "8px",
      minWidth: "206px",
    },
    "& .MuiAccordionDetails-root": {
      backgroundColor: "#ffffff",
      padding: "10px",
    },
  },
}));
