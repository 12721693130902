import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const position = toast.POSITION.TOP_CENTER;

function displayToast(message, emoji, options) {
    toast.dismiss();
    toast(`${emoji} ${message}`, { ...options, position: position });
}

export function error(message, emoji = "") {
    displayToast(message, emoji, { type: toast.TYPE.ERROR });
}

export function success(message, emoji = "") {
    displayToast(message, emoji, { type: toast.TYPE.SUCCESS });
}

export function warning(message, emoji = "") {
    displayToast(message, emoji, { type: toast.TYPE.WARNING });
}
export function info(message, emoji = "") {
    displayToast(message, emoji, { type: toast.TYPE.INFO });
}
