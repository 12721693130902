import { createSlice } from "@reduxjs/toolkit";

export const termsMedsApiName = "termsMeds";

export const initialTermsMedsStateValue = {
  termMedsIsLoading: false,
  queryData: "",
};
export const termsMedsSlice = createSlice({
  name: termsMedsApiName,
  initialState: initialTermsMedsStateValue,
  reducers: {
    getTermsMeds: (state, action) => {
      state.termMedsIsLoading = true;
    },
    getTermsMedsSuccess: (state, action) => {
      state.termMedsIsLoading = false;
      state.queryData = action.payload?.data;
    },
    getTermsMedsFail: (state, action) => {
      state.termMedsIsLoading = false;
      state.queryData = "";
    },
    clearTermsMedsData: (state, action) => {
      state.termMedsIsLoading = false;
      state.queryData = "";
    },
  },
});

export const {
  getTermsMeds,
  getTermsMedsSuccess,
  getTermsMedsFail,
  clearTermsMedsData,
} = termsMedsSlice.actions;

export default termsMedsSlice.reducer;

export const getTermsMedsList = (searchTerm) => ({
  payload: {
    apiName: termsMedsApiName,
    method: "get",
    url: `https://kwslnsn5ztguzqsrgwplc2dlzi0usgyq.lambda-url.us-west-2.on.aws/?term=${searchTerm}`,
  },
  type: "getTermsMeds",
});
