import { Divider } from "@mui/material";
import { memo, useContext, useEffect, useState } from "react";
import Text from "components/common/Typography/Text";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addAllergy, deleteAllergy, fetchAllergies, updateAllergy } from "apiClients/allergies";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import { SubForm } from "../common/SubForm";

const radioOptions = [
    { value: "allergy", label: "Allergy" },
    { value: "intolerance", label: "Intolerance" },
]

const allergyInputs = [
    {
        label: "Name",
        key: "source",
        cols: 4,
    },
    {
        label: "Reaction",
        key: "reaction",
        cols: 4,
    },
    {
        label: "Type",
        key: "type",
        cols: 4,
        type: "radio",
        radioOptions
    },
];

export const AllergyReview = memo(({ patientId }) => {
    const queryClient = useQueryClient();
    const { registerForm } = useContext(IntakeFormContext);
    const [values, setValues] = useState([]);

    const mutation = useMutation({
        mutationFn: async (dirtyValue) => {
            if (dirtyValue.id) {
                if (dirtyValue.delete) {
                    // If delete is set we need to delete the allergy.
                    return deleteAllergy(patientId, dirtyValue.id);
                } else {
                    // If no delete is set, we need to update the allergy since it is dirty (changed).
                    return updateAllergy(patientId, dirtyValue.id, dirtyValue);
                }
            } else {
                // If no ID is present, it is a new allergy and we need to add it.
                return addAllergy(patientId, dirtyValue);
            }
        },

        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["allergies", patientId]
            });
        },
    });

    useEffect(() => {
        return () => {
            queryClient.removeQueries({
                queryKey: ["allergies", patientId]
            });
        }
    }, [queryClient, patientId]);

    const { data, isLoading } = useQuery({
        queryKey: ["allergies", patientId],
        queryFn: async () => await fetchAllergies(patientId),
    });

    const mapResultsForForm = ((results = []) => {
        return results.map(result => {
            return {
                ...result,
                reaction: result.adverse_reactions?.join(', ') ?? '',
            }
        });
    })

    const mapObjectForApi = (values) => {
        return values.map((value) => {
            return {
                ...value,
                adverse_reactions: value.reaction?.split(',').map((reaction) => reaction.trim())
            }
        });
    };

    const handleSubmit = async (dirtyValues) => {
        const operations = dirtyValues.map((dirtyValue) => {
            return mutation.mutateAsync(dirtyValue);
        });
        return Promise.allSettled(operations);
    }

    useEffect(() => {
        const mappedResults = mapResultsForForm(data?.results);
        registerForm('allergies', handleSubmit, mappedResults, mapObjectForApi);
        setValues(mappedResults);
    }, [data]);

    return <>
        <Text variant="h1">Allergy Review</Text>

        <Divider sx={{ my: 2 }} />
        
        <SubForm formKey="allergies" subSections={allergyInputs} dataLoading={isLoading} data={values} />
    </>
});