import { createSlice } from "@reduxjs/toolkit";

export const initialIcdCodeState = {
  count: 0,
  next: null,
  icdCodes: [],
  previous: null,
  icdCodesOptions: [],
  isIcdCodeLoading: false,
};

const name = "icdCode";

export const icdCodeSlice = createSlice({
  name,
  initialState: initialIcdCodeState,
  reducers: {
    getIcdCode: (state, action) => {
      state.isIcdCodeLoading = true;
    },
    getIcdCodeSuccess: (state, action) => {
      state.isIcdCodeLoading = false;
      state.icdCodes = action.payload.results?.length
        ? action.payload.results
        : state.icdCodes;
      state.icdCodesOptions = action.payload.results?.length
        ? action.payload.results.map((s) => ({
            label: s.icd_code,
            value: s.id,
            description: s.description,
          }))
        : state.icdCodesOptions;
    },
    getIcdCodeFail: (state, action) => {
      state.icdCodes = [];
      state.icdCodesOptions = [];
      state.isIcdCodeLoading = false;
    },
    clearIcdCode: (state, action) => {
      state.icdCodes = [];
      state.icdCodesOptions = [];
      state.isIcdCodeLoading = false;
    },
  },
});

export const { getIcdCode } = icdCodeSlice.actions;

export default icdCodeSlice.reducer;

export const getIcdCodeList = (searchQuery) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/icds/search/?q=${searchQuery}`,
  },
  type: "getIcdCode",
});
