import { createSlice } from "@reduxjs/toolkit";

export const initialRecordingStates = {
  recordingIsLoading: false,
  recordingSuccess: null,
  recordingPostSuccess: null,
  recPosted: null,
  processingStatus: null,
  recordingPostLoading: false,
  recordingData: [],
  isRecordingAvaiable: false,
  dictation: "",
};

const name = "recording";

export const recordingSlice = createSlice({
  name,
  initialState: initialRecordingStates,
  reducers: {
    getRecording: (state, action) => {
      state.recordingIsLoading = true;
    },
    getRecordingSuccess: (state, { payload }) => {
      state.recordingIsLoading = false;
      state.processingStatus = payload?.[0]?.processing_status
      state.recordingSuccess = true;
      state.recordingData = payload?.[0]?.end_note_data
    },
    getRecordingFail: (state, action) => {
      state.recordingIsLoading = false;
      state.recordingSuccess = false;
      state.recordingData = [];
    },
    updateUploadingStatus: (state, action) => {
      state.isRecordingAvaiable = true;
    },
    //post recording data
    postRecording: (state, action) => {
      state.recordingPostLoading = true;
    },
    postRecordingSuccess: (state, action) => {
      state.recPosted = 'posted';
      state.recordingPostLoading = false;
      state.recordingPostSuccess = true;
      state.recordingData = action.payload?.[0]?.end_note_data;
    },
    postRecordingFail: (state, action) => {
      state.recPosted = 'posted';
      state.recordingPostLoading = false;
      state.recordingPostSuccess = false;
      state.recordingData = [];
    },
    saveDictation: (state, { payload }) => {
      state.dictation = state.dictation + " " + payload;
    },
    clearRecordingData: (state, action) => {
      state.recordingIsLoading = false;
      state.isRecordingAvaiable = false;
      state.recordingSuccess = null;
      state.dictation = "";
      state.recordingPostSuccess = null;
      state.recordingPostLoading = null;
      state.recordingData = [];
    },
    extraReducers: (builder) => {
      builder.addCase('postRecordingSuccess', (state, action) => {
        state.recordingPostSuccess = true;
        state.recordingData = action.payload?.[0]?.end_note_data;
        state.recordingPostLoading = false;
        // Dispatch getRecording action after postRecordingSuccess
        return { ...state, shouldFetchRecording: true };
      })
    }
  },
});

export const {
  getRecording,
  updateUploadingStatus,
  saveDictation,
  clearRecordingData,
} = recordingSlice.actions;

export default recordingSlice.reducer;

export const getRecordingData = (appointmentId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/encounters/${appointmentId}/recordings/`,
  },
  type: "getRecording",
});

export const postRecordingData = (encounterId, data) => ({
  payload: {
    apiName: name,
    method: "post",
    data,
    formData: true,
    url: `api/encounters/${encounterId}/recordings/`
  },
  type: "postRecording",
});
