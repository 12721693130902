import {
  configureStore,
  createImmutableStateInvariantMiddleware,
} from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { api } from "services/api";
import { logger } from "services/logger";
import reducer from "./features/combineReducer";

const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
  ignoredPaths: ["ignoredPath", "ignoredNested.one", "ignoredNested.two"],
});

const persistConfig = {
  key: "root",
  storage,
  timeout: null,
  version: 1,
  whitelist: ["auth", "options", "admin", "flags", "locations"],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, reducer);
const store = configureStore({
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["payload.onFail", "payload.onSuccess"],
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    logger,
    immutableInvariantMiddleware,
    api,
  ],
  reducer: persistedReducer,
});
const persistor = persistStore(store);

export { persistor, store };

