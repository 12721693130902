import { createSlice } from "@reduxjs/toolkit";

export const initialPharmacyState = {
  pharmacyList: [],
  searchedPharmaciesNextPage: null,
  pharmacyIsLoading: false,
  pharmacyAddingIsLoading: false,
  pharmacyAddedSuccess: false,
  pharmacyUpdatingIsLoading: false,
  pharmacyUpdateSuccess: false,
  pharmacyOptions: [],
  searchedPharmaciesList: [],
  searchedPharmaciesOptions: [],
  searchPharmacyInProcess: false,
};

const name = "pharmacy";

export const pharmacySlice = createSlice({
  name,
  initialState: initialPharmacyState,
  reducers: {
    getPharmacy: (state, action) => {
      state.pharmacyIsLoading = true;
    },
    getPharmacySuccess: (state, action) => {
      state.pharmacyIsLoading = false;
      state.pharmacyList = action.payload?.results;
      state.pharmacyOptions = action.payload?.results?.map(
        ({ pharmacy_name, id }) => ({ value: id, label: pharmacy_name })
      );
    },
    getPharmacyFail: (state, action) => {
      state.pharmacyList = [];
      state.searchedPharmaciesNextPage = null;
      state.pharmacyIsLoading = false;
    },

    //search pharmacy
    getSearchPharmacy: (state, action) => {
      state.searchPharmacyInProcess = true;
    },
    getSearchPharmacySuccess: (state, action) => {
      state.searchPharmacyInProcess = false;
      state.searchedPharmaciesNextPage = action.payload?.next;
      state.searchedPharmaciesList = action.payload?.results;
      state.searchedPharmaciesOptions = action.payload?.results?.map(
        ({ pharmacy_name, pharmacy_id, address, phone }) => ({ value: address, id: pharmacy_id, phone, label: pharmacy_name })
      );
    },
    getSearchPharmacyFail: (state, action) => {
      state.pharmacyList = [];
      state.searchedPharmaciesNextPage = null;
      state.searchPharmacyInProcess = false;
    },

    clearPharmacyData: (state, action) => {
      state.pharmacyIsLoading = false;
    },

    //adding pharmacy
    addPharmacy: (state, action) => {
      state.pharmacyAddingIsLoading = true;
      state.pharmacyAddedSuccess = false;
    },
    addPharmacySuccess: (state, action) => {
      state.pharmacyAddingIsLoading = false;
      state.pharmacyAddedSuccess = true;
    },
    addPharmacyFail: (state, action) => {
      state.pharmacyAddingIsLoading = false;
      state.pharmacyAddedSuccess = false;
    },

    //updating pharmacy
    updatePharmacy: (state, action) => {
      state.pharmacyUpdatingIsLoading = true;
      state.pharmacyUpdateSuccess = false;
    },
    updatePharmacySuccess: (state, action) => {
      state.pharmacyUpdatingIsLoading = false;
      state.pharmacyUpdateSuccess = true;
    },
    updatePharmacyFail: (state, action) => {
      state.pharmacyUpdatingIsLoading = false;
      state.pharmacyUpdateSuccess = false;
    },

    ClearPharmacyStates: (state, action) => {
      state.pharmacyAddingIsLoading = false;
      state.pharmacyAddedSuccess = false;
      state.pharmacyUpdatingIsLoading = false;
      state.pharmacyUpdateSuccess = false;
    },
    clearPharmacyData: (state, action) => {
      state.pharmacyList = [];
      state.pharmacyOptions = [];
      state.searchedPharmaciesList = [];
      state.searchedPharmaciesOptions = []
    },
    clearSearchPharmacyStates: (state, action) => {
      state.searchPharmacyInProcess = false;
      state.searchedPharmaciesNextPage = null;
      state.searchedPharmaciesList = [];
      state.searchedPharmaciesOptions = []
    },
  },
});

export const { getPharmacy, clearPharmacyData, ClearPharmacyStates, clearSearchPharmacyStates } =
  pharmacySlice.actions;

export default pharmacySlice.reducer;

export const getPharmacyList = (patiendId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/patients/${patiendId}/pharmacy/`,
  },
  type: "getPharmacy",
});

export const AddPharmacy = (data, patiendId) => ({
  payload: {
    apiName: name,
    method: "post",
    data,
    url: `/api/patients/${patiendId}/pharmacy/`,
  },
  type: "addPharmacy",
});

export const deletePharmacy = (patiendId, pharmacyId) => ({
  payload: {
    apiName: name,
    method: "delete",
    url: `/api/patients/${patiendId}/pharmacy/${pharmacyId}/`,
  },
  type: "addPharmacy", //using single action for both add and delete
});

export const UpdatePharmacyById = () => ({
  payload: {
    apiName: name,
    method: "put",
    url: `/api/pharmacy/`,
  },
  type: "updatePharmacy",
});

export const getSearchPharmacy = (query, patientId, zip_code, page = 1, signal) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `api/patients/${patientId}/pharmacy/search/?zip=${zip_code}&name=${query}&pagenumber=${page}`,
    signal: signal,
  },
  type: "getSearchPharmacy",
});
