import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#1b1b1b",
    width: "auto",
    height: "auto",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    "& .MuiTooltip-arrow": {
      color: "#FFFFFFFB",
    },
  },
}));

const CustomTooltip = ({
  children,
  tooltipText,
  arrow = false,
  placement = "top",
  clickToOpen = { isOpenOnClickEnabled: false, closeFn: () => {}, isOpen: false },
}) => {

  const { isOpenOnClickEnabled, closeFn, isOpen } = clickToOpen 

  return (
    <LightTooltip
      title={tooltipText}
      placement={placement}
      arrow={arrow}
      open={isOpenOnClickEnabled ? isOpen : undefined} 
      onClose={isOpenOnClickEnabled ? closeFn : undefined} 
      disableFocusListener={isOpenOnClickEnabled}
      disableHoverListener={isOpenOnClickEnabled}
      disableTouchListener={isOpenOnClickEnabled}
    >
      <span
        style={{ cursor: clickToOpen ? "pointer" : "default" }}
      >
        {children}
      </span>
    </LightTooltip>
  );
};

export default CustomTooltip;