import { Box, Grid } from "@mui/material";
import CustomModal from "components/common/Modal/CustomModal";
import * as toast from "hooks/notify";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import HorizontalTabs from "components/common/Tabs/HorizontalTabs";
import HealthSummary from "./components/HealthSummary";
import Medications from "./components/Medications/Medications";
import { healthsummaryMain } from "styles/SCD/healthSummaryStyle";
import {
  clearAllHistoryData,
  getAllHistoryList,
} from "redux/features/AllHistories/AllHistorySlice";
import {
  clearAllergiesData,
  getAllergiesByPatientId,
} from "redux/features/Allergies/AllergiesSlice";
import SmartSummaryIcon from "../../../assets/svg/light/ai_sparkle.svg";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassFullRoundedIcon from '@mui/icons-material/HourglassFullRounded';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import {
  clearEncounterData,
  createOrUpdateEncounter,
  getEncounterByAppointment,
} from "redux/features/Encounter/EncounterSlice";
import { getFamilyHistoryList } from "redux/features/FamilyHistory/FamilyHistorySlice";
import {
  clearMedicationsData,
} from "redux/features/Medications/MedicationsSlice";
import {
  clearMedicinesData
} from "redux/features/Medications/MedicinesSlice";
import { clearAllOrderMedicationsStates, getOrderMedicationsList } from "redux/features/Medications/OrderMedicationsSlice";
import {
  clearLabsResultsMessage,
  getLabsResults,
} from "redux/features/Patients/ViewLabResultsSlice";
import {
  clearSpecificPatientData,
  getSpecificPatientDetails,
  refreshPatientData,
} from "redux/features/Patients/getSpecificPatientsSlice";
import { clearVitalsData } from "redux/features/Vitals/VitalsSlice";
import { DEBOUNCE_DELAY, debounce } from "utils/debouncer";
import EndVisit from "./components/EndVisit";
import Plan from "./components/AssesmentAndPlan/Plan";
import VitalLabMedWrapper from "./components/VitalLabMedWrapper";
import SCDHeader from "./components/SCDHeader";
import Button from "components/common/Button/Button";
import rightArrow from "assets/svg/light/rightArrow.svg";
import ChatBotComponent from "components/common/ChatBotComponent/ChatBotComponent";
import PastVisitModal from "./components/PastVisit/PastVisitModal";
import {
  planBoxDescriptionStyling,
  planBoxStylingWrapper,
  planBoxTitleStyling,
} from "styles/SCD/planStyles";
import Text from "components/common/Typography/Text";
import AssesmentPlanNotes from "./components/AssesmentAndPlan/components/AssesmentPlanNotes";
import ChatBotDrawer from "components/common/ChatBotComponent/ChatBotDrawer";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import InputField from "components/common/FormComponents/InputField";
import { clearLabsList, getLabsList } from "redux/features/Labs/labsSlice";
import { clearPharmacyData } from "redux/features/Pharmacy/PharmacySlice";
import { removeSnakeCasingAndCapitalize } from "utils/convertJsonToMarkDown";
import { useFeedback } from "hooks/useFeedback";

const PatientDetail = () => {
  const viewWidth = window.innerWidth;
  const dispatch = useDispatch();
  let { patientId, appointmentId } = useParams();
  const [isPostCalled, setIsPostCalled] = useState(null);
  const [startAppointment, setStartAppointment] = useState(false);
  const { isChatOpen } = useSelector((state) => state.globalStates);
  const { processingStatus, recordingIsLoading } = useSelector((state) => state.recording);
  const { account_id, userId } = useSelector((state) => state.auth);
  const [feedbackNote, setFeedbackNote] = useState("")
  const [otherEncounter, setOtherEncounter] = useState({})
  const [smartSummaryfeedback, setsmartSummaryFeedback] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [endNoteFeedback, setEndNoteFeedback] = useState("");
  const { feedbackList, mutationFeedback } = useFeedback();
  const scdRef = useRef();
  const markdownContentRef = useRef();
  const labAbortControllerRef = useRef(null);

  // encounter request response
  const {
    encounter,
    encounterSuccess,
    encounterMessage,
    isEncounterLoading,
  } = useSelector((state) => state.encounter);

  const {
    activeLocation: { value: activeLocationId } = {}
  } = useSelector((state) => state.locations);

  //get query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [open, setOpen] = useState(queryParams.get("review_later") ?? false);
  const [pastVisit, setPastVisit] = useState(false);
  const [planMedicineData, setPlanMedicineData] = useState([]);
  // States to not run debouncer when default data comer from the Api.
  const [enableAutoSaveNotes, setEnableAutoSaveNotes] = useState(false);
  // State for assesment components
  const [assesmentObj, setAssesmentObj] = useState({
    notes: "",
  });

  useEffect(() => {
    if (feedbackList?.length) {
      const findSmartSummaryFeedback = findLatestFeedback(feedbackList, "smart.summary");
      const findEndNoteFeedback = findLatestFeedback(feedbackList, "smart.endnote");
      setEndNoteFeedback(findEndNoteFeedback?.thumbs_up);
      setsmartSummaryFeedback(findSmartSummaryFeedback?.thumbs_up);
    }
  }, [feedbackList, patientId, otherEncounter, encounter]);

  const findLatestFeedback = (feedbackArray, itemType) => {
    // Smart summary is associated with a patient and endnote is associated with an encounter
    const itemId = itemType === "smart.summary" ? patientId : otherEncounter?.id ? otherEncounter?.id : encounter?.id;
    return feedbackArray
      .filter(
        (item) =>
          item.item_for_feedback === itemType &&
          Number(item.item_id) === Number(itemId)
      )
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];
  };

  // Toast pop up on encounter response
  useEffect(() => {
    if (encounterSuccess === false) {
      toast.error(encounterMessage);
    }
  }, [encounterMessage]);



  useEffect(() => {
    // Api hit for labs data
    dispatch(getLabsResults(patientId));

    // Api hit for familyhistory for specific patient
    dispatch(getFamilyHistoryList(patientId));

    // Api hit for specific patient
    dispatch(getSpecificPatientDetails(patientId));

    // Api hit for allergies by patient id
    dispatch(getAllergiesByPatientId(patientId));

    // Api hit for all histories by patient id
    dispatch(getAllHistoryList(patientId));

    // precriptions for enocunter
    dispatch(getOrderMedicationsList(patientId));

    // Trigger the smart summary data
    dispatch(refreshPatientData(patientId));

    appointmentId && dispatch(getEncounterByAppointment(appointmentId));
  }, [patientId]);

  useEffect(() => {
    encounter?.sign_close_ind === "c" &&
      setStartAppointment(false);
  }, [encounter]);

  useEffect(() => {
    return () => {
      // Clear vitals data
      dispatch(clearVitalsData());

      // clear labs data
      dispatch(clearLabsResultsMessage());

      // Clear patient data from global state
      dispatch(clearSpecificPatientData());

      // Clear all history data from global state
      dispatch(clearAllHistoryData());

      // Clear all medicines data
      dispatch(clearMedicinesData());

      // Clear encounter data from global state
      dispatch(clearEncounterData());

      // Clear encounter data by appointment
      dispatch(clearAllergiesData());

      // Clear all medications data
      dispatch(clearMedicationsData())

      //clear pharmacy data
      dispatch(clearPharmacyData())

      //clear orderMedication/prescriptions data
      dispatch(clearAllOrderMedicationsStates())

      // clear labs list and stop current api request
      _clearLabsData()
    };
  }, []);

  // Triggers and restart the autosaving process when notes changes
  useEffect(() => {
    const debouncedFunc = callAutoEncSaver(
      3000,
      () => createOrUpdate("Soap Notes", "s"),
      enableAutoSaveNotes
    );
    debouncedFunc();

    return () => {
      // Clear the debounce timer on unmount or when notes change
      debouncedFunc.cancel();
    };
  }, [assesmentObj?.notes]);

  const getLabs = debounce((value) => {
    if (value?.length > 0) {

      // Cancel previous request if it exists
      abortLabsCurrentRequest()

      // Create a new AbortController
      const abortController = new AbortController();
      labAbortControllerRef.current = abortController;

      dispatch(getLabsList(value, labAbortControllerRef.current.signal));
    }
  }, DEBOUNCE_DELAY);

  // Cancel previous request if it exists
  function abortLabsCurrentRequest() {
    if (labAbortControllerRef.current) {
      labAbortControllerRef.current.abort();
    }
  }

  function _clearLabsData() {
    abortLabsCurrentRequest()
    dispatch(clearLabsList());
  }

  // To send the encounter after validations
  function sendEncounter(plan_note, signCloseIn) {
    let encounterData = {
      plan_note: plan_note,
      patient_id: patientId,
      location_id: activeLocationId,
      practitioners: [],
      user_id: userId,
      visit_type: "",
      outcome: "",
      assessment_notes: assesmentObj?.notes ?? ".",
      appointment_id: appointmentId ?? null,
      icds: [],// we are not sure yet whether we need icds for the mvp or not...
      cpts: [],
      datetime: new Date().toISOString(),
      followup_date: null,
      account_id: account_id,
      followup_selection: '',
      sign_close_ind: signCloseIn,
    };

    // adding id if encounter exists
    if (encounter?.id) {
      encounterData.id = encounter?.id;
    }

    // Clear encounter data from global state
    dispatch(clearEncounterData());

    // create or update encounter on the basis of data and validations
    dispatch(
      createOrUpdateEncounter(
        encounterData,
        encounter?.id ? "PUT" : "POST",
        encounter?.id
      )
    );
  }

  const patchEncounter = (encounterID, encounterStatus, plan_note) => {
    if (encounterID) {
      dispatch(createOrUpdateEncounter(
        {
          ...(plan_note ? { plan_note: plan_note } : {}),
          id: encounterID,
          sign_close_ind: encounterStatus ? encounterStatus : "r"
        },
        "PATCH",
        encounterID
      ));
    }
    setOpen(false)
    setStartAppointment(false)
    setPastVisit(false)
    setOtherEncounter({})
  }

  function createOrUpdate(planNotes, signCloseIn) {
    if (signCloseIn === "s" || signCloseIn === "r" || signCloseIn === "c") {
      sendEncounter(planNotes, signCloseIn);
    }
  }

  function callAutoEncSaver(timeOut, func, condition) {
    return debounce(() => {
      if (condition) {
        func();
      }
    }, timeOut);
  }

  const handleCancel = () => {
    setOpen(false);
    setPastVisit(false);
    setOtherEncounter({});
  };

  function onStartAppointment() {
    setStartAppointment(true);
    if (!appointmentId) {
      dispatch(createOrUpdateEncounter(
        {
          patient_id: patientId,
          location_id: activeLocationId,
          user_id: userId,
          datetime: new Date().toISOString(),
          account_id: account_id,
          sign_close_ind: "s",
        }, "POST", null
      ));
    }
  }

  const healthMedTabsArray = [
    {
      title: "Smart Summary",
      tab: <HealthSummary
        smartSummaryfeedback={smartSummaryfeedback}
        setsmartSummaryFeedback={setsmartSummaryFeedback}
      />,
      tabIcon: true,
      tabIconSrc: SmartSummaryIcon,
      left: true,
    },
    {
      title: "Medications",
      tab: <Medications />,
    },
  ];

  const handleCloseEndVisit = (notes) => {
    createOrUpdate(notes ?? "", "c");
  };

  const handleFeedbackStatus = (feedback) => {
    setFeedbackNote("")
    setShowFeedbackModal(false)
    setEndNoteFeedback(feedback)
    const payload = {
      "feedback": feedbackNote ?? "",
      "item_for_feedback": "smart.endnote",
      "thumbs_up": feedback,
      "user_id": userId,
      "item_id": (otherEncounter?.id || encounter?.id) ?? ""
    }
    mutationFeedback.mutateAsync(payload);
  }

  const handleEndVisitClick = () => {
    const planNotesInMarkDownFormat = markdownContentRef?.current?.getPlanNotesInMarkdownFormat();
    otherEncounter?.id ? patchEncounter(otherEncounter?.id, "c", planNotesInMarkDownFormat) : handleCloseEndVisit(planNotesInMarkDownFormat)
  }

  const getStatusIcon = (status) => {
    switch (status) {
      case 'NOT_STARTED':
        return <NotStartedIcon />;
      case 'PROCESSING':
        return <HourglassFullRoundedIcon />;
      case 'COMPLETED':
        return <CheckCircleIcon />;
      case 'FAILED':
        return <ErrorIcon />;
    }
  }

  return (
    <Box>
      <CustomModal
        fullWidth
        maxWidth="md"
        title="Smart Notes Feedback"
        open={showFeedbackModal}
        setOpen={() => {
          handleFeedbackStatus(false)
        }}
        actions={[
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              text={"Cancel"}
              onClick={() => handleFeedbackStatus(false)}
              sx={{ marginRight: "10px", width: "150px" }}
              border={"1px solid #272A40"}
              backgroundColor="#FFFFFF"
              borderRadius="10px"
              color="#272A40"
              btnColor="#272A40"
            />
            <Button
              onClick={() => handleFeedbackStatus(false)}
              text={"Save"}
              sx={{ width: "150px" }}
              backgroundColor="#315FFF"
              borderRadius="10px"
              color="#fff"
              btnColor="#fff"
            />
          </Box>
        ]}
      >
        <Box paddingY={2} paddingX={2}>
          <Text variant="h3" marginY={1}>Feedback</Text>
          <InputField
            rows={4}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            flex={1}
            multiline
            name={"scd-assesment-notes"}
            labelVariant="formLabel"
            value={feedbackNote}
            onChange={(e) => setFeedbackNote(e.target.value)}
          />
        </Box>
      </CustomModal>
      <CustomModal
        open={open}
        titleIcon={
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {getStatusIcon(processingStatus || (otherEncounter?.plan_note && "COMPLETED"))}
            <Box sx={{ margin: "3px 0px 0px 5px" }}>
              {processingStatus
                ? removeSnakeCasingAndCapitalize(processingStatus?.toLowerCase())
                : otherEncounter?.plan_note
                  ? "Completed"
                  : ""}
            </Box>
          </Box>
        }
        setOpen={() => {
          setOtherEncounter({})
          setOpen(false)
        }}
        title={"Finalize Assessment and Plan Note"}
        fullWidth
        maxWidth="lg"
        actions={[
          //added more checks for checking whether we recieved the smart notes or not
          !(!((otherEncounter?.plan_note || otherEncounter?.smart_note_data) || (encounter?.plan_note || encounter?.smart_note_data)) || recordingIsLoading || processingStatus === "processing") &&
          <Box position={"relative"}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"end"} flex={1}>
              {
                (otherEncounter?.sign_close_ind ? otherEncounter?.sign_close_ind === "c" : encounter?.sign_close_ind === "c") ?
                  <Button
                    text="Close"
                    color="#ffffff"
                    backgroundColor="#1344f1"
                    btnColor="#ffffff"
                    borderRadius="10px"
                    onClick={handleCancel}
                  />
                  :
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
                      <ThumbUpIcon onClick={() => handleFeedbackStatus(true)} sx={{ color: (endNoteFeedback === "" || endNoteFeedback == undefined) ? "#ccc" : (endNoteFeedback ? "#246EFD" : "#ccc"), cursor: "pointer" }} />
                      <ThumbDownIcon onClick={() => setShowFeedbackModal(true)} sx={{ color: (endNoteFeedback === "" || endNoteFeedback == undefined) ? "#ccc" : (endNoteFeedback ? "#ccc" : "#246EFD"), marginLeft: "14px", cursor: "pointer" }} />
                    </Box>
                    {
                      (otherEncounter?.sign_close_ind !== "r" ?? encounter?.sign_close_ind !== "r") &&
                      <Button
                        text="Review Later"
                        backgroundColor="transparent"
                        color="#246EFD"
                        btnColor="#246EFD"
                        border={"1px solid #246EFD"}
                        disabled={isEncounterLoading}
                        borderRadius="12px"
                        sx={{ padding: "1px 20px", marginRight: "20px" }}
                        onClick={() => {
                          const encId = otherEncounter?.id ? otherEncounter?.id : encounter?.id
                          const planNotesInMarkDownFormat = markdownContentRef?.current?.getPlanNotesInMarkdownFormat();
                          patchEncounter(encId, "r", planNotesInMarkDownFormat)
                        }}
                      />
                    }
                    <Button
                      text="End Visit"
                      backgroundColor="#246EFD"
                      color="#FFFFFF"
                      btnColor="#FFFFFF"
                      borderRadius="12px"
                      sx={{ padding: "1px 20px", marginRight: "20px" }}
                      rightSide
                      isLoading={isEncounterLoading}
                      iconDetails={{ allowIcon: true, icon: rightArrow }}
                      data-testid={"encounter-end-visit-button-id"}
                      onClick={handleEndVisitClick}
                    />
                  </>
              }
            </Box>
          </Box>
        ]}
      >
        <EndVisit
          getLabs={getLabs}
          clearLabsData={_clearLabsData}
          ref={markdownContentRef}
          isPostCalled={isPostCalled}
          planMedicineData={planMedicineData}
          setPlanMedicineData={setPlanMedicineData}
          encounter={otherEncounter?.id ? otherEncounter : encounter}
        />
      </CustomModal>
      <CustomModal
        open={pastVisit}
        setOpen={setPastVisit}
        title={"Past Visit"}
        fullWidth
        maxWidth="md"
        actions={[
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              text="Close"
              color="#ffffff"
              backgroundColor="#1344f1"
              btnColor="#ffffff"
              borderRadius="10px"
              onClick={handleCancel}
            />
          </Box>,
        ]}
      >
        <PastVisitModal setOtherEncounter={setOtherEncounter} setOpenEndVisit={setOpen} setPastVisit={setPastVisit} />
      </CustomModal>
      <Grid container spacing={2}>
        <Grid
          item
          lg={12}
          xl={12}
          xxl={12}
          md={12}
          sm={12}
          xs={12}
        >
          <SCDHeader
            ref={scdRef}
            setIsPostCalled={setIsPostCalled}
            patientId={patientId}
            setStartAppointment={onStartAppointment}
            startAppointment={startAppointment}
            setOpen={setOpen}
            setPastVisit={setPastVisit}
          />
          <Box sx={{ marginTop: "23px" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                className="healthsummary_wrapper"
                sx={{ flex: 1 }}
              >
                <Box
                  sx={{ ...healthsummaryMain(), height: "100%", zIndex: 1 }}
                  data-testid="scd-health-summary-box-id"
                >
                  <HorizontalTabs childrenArray={healthMedTabsArray} />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                data-testid="scd-vital-labs-box-id"
                className="vital-lab-med-wrapper"
                sx={{ height: "100%" }}
              >
                <Box sx={{
                  ...healthsummaryMain(),
                  height: "50vh",
                }}>
                  <VitalLabMedWrapper />
                </Box>
                <Box
                  sx={{ position: "relative", marginTop: "5px", }}
                >
                  <Box
                    sx={{
                      marginTop: "15px",
                      height: "50vh",
                    }}
                    data-testid="scd-plan-box-id"
                  >
                    <Box
                      sx={{
                        ...planBoxStylingWrapper,
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={planBoxTitleStyling(viewWidth)}
                        data-testid={"assesment-and-plan-heading-id"}
                      >
                        <Text variant="h3">Assessment and Plan</Text>
                      </Box>
                      <Box sx={planBoxDescriptionStyling(viewWidth)}>
                        {startAppointment ||
                          encounter?.sign_close_ind === "c" ? (
                          <>
                            <Box>
                              <Text variant="h3">Notes</Text>
                              <AssesmentPlanNotes
                                setAssesmentObj={setAssesmentObj}
                                assesmentObj={assesmentObj}
                                setEnableAutoSaveNotes={setEnableAutoSaveNotes}
                                enableAutoSaveNotes={enableAutoSaveNotes}
                              />
                            </Box>
                            <Grid container sx={{ marginTop: "10px" }}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Plan
                                  getLabs={getLabs}
                                  clearLabsData={_clearLabsData}
                                  planMedicineData={planMedicineData}
                                  setPlanMedicineData={setPlanMedicineData}
                                />
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <Box
                            sx={{
                              minHeight: "40vh",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text variant="bodyS">
                              <span
                                style={{ cursor: "pointer", color: "#246EFD" }}
                                onClick={() => {
                                  encounter?.sign_close_ind === "r" ? setPastVisit(true) : scdRef.current.startRecordingProcessFunc();
                                }}
                              >
                                {encounter?.sign_close_ind === "r" ? "Review past visit" : "Start the visit"}
                              </span>{" "}
                              {encounter?.sign_close_ind === "r" ? "" : " to begin filling out this section."}
                            </Text>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {encounter?.sign_close_ind === "c" && (
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgb(253 250 250 / 50%)",
                        zIndex: 1000,
                        pointerEvents: "auto",
                        cursor: "not-allowed",
                      }}
                    ></div>
                  )}
                </Box>
                {/* </Box> */}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <>
          {isChatOpen === true ? (
            <>
              {viewWidth < 1200 ? (
                <ChatBotDrawer isChatOpen={true} />
              ) : (
                <ChatBotComponent isChatOpen={true} />
              )}
            </>
          ) : (
            ""
          )}
        </>
      </Grid>
    </Box>
  );
};

export default PatientDetail;
