import { alignItemsCenter, flexRow } from "styles"

// Styling for UPPER HEADER
export let mainPlanBoxStyling = {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    padding: "10px",
    marginTop: "10px",
    height: "100%",
}
export let planBoxStylingWrapper = {
    backgroundColor: "#FFFFFF",
    borderRadius: "24px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
};
export let planBoxStyling = {
    border: "1px solid #315FFF",
    borderRadius: "100px",
    padding: "2px",
    width: "fit-content",
    height: "auto",
}
export let planBoxTitleStyling = (viewWidth) => {
    return {
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
        color: "#2D3748",
        fontSize: "14px",
        fontWeight: "700",
        borderBottom: "2px solid #E1E1E1",
        height: "auto",
        maxHeight:"170px",
        padding:
            viewWidth >= 600 && viewWidth <= 1024
                ? "10px 20px 35px 20px"
                : "20px 20px",
    };
};
export let planBoxDescriptionStyling = (viewWidth) => {
    return {
      backgroundColor: "transparent",
      color: "#2D3748",
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      padding:
        viewWidth >= 600 && viewWidth <= 1024
          ? "10px 20px 35px 20px"
          : "10px 20px",
    };
  };
export let planBoxHeaderStyling = {
    backgroundColor: "#D0DAFC",
    padding: "6px 20px",
    color: "#000000",
    borderRadius: "100px",
    boxShadow: "none",
    fontSize: "15px",
    fontWeight: "700",
    width: "159px",
    height: "31px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}
export let planInputBoxStyling = {
    maxHeight: "210px",
    overflowY: "auto",
    minHeight: "210px"
}
export let planDatePickerStyling = {
    background: "transparent",
    border: "1px solid black",
    boxShadow: "none",
}
export let planStripsStyling = (stripEanbled) => {
    return {
        backgroundColor: stripEanbled ? "rgb(94, 188, 78)" : "#B3B4BD",
        cursor: "pointer",
        color: "#ffffff",
        minWidth: "100%",
        height: "auto",
        fontSize: "12px",
        lineHeight: "13px",
        fontWeight: "700",
        borderRadius: "5px",
        display: "flex",
        padding: "10px",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "6px",
    }
}
export let newPlanStripDesign = {
    backgroundColor: false ? "rgb(94, 188, 78)" : "#B3B4BD",
    cursor: "pointer",
    color: "#ffffff",
    minWidth: "100%",
    height: "auto",
    fontSize: "12px",
    lineHeight: "13px",
    fontWeight: "700",
    borderRadius: "5px",
    display: "flex",
    padding: "10px",
    justifyContent: false ? "space-around" : "space-between",
    alignItems: "center",
    marginBottom: "6px",
}
export let planFollowUpStyling = {
    display: "flex",
    alignItems: "flex-start",
}
export let planNotesBoxStyling = {
    ...flexRow,
    ...alignItemsCenter
}
export let planImageBoxStyling = {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    marginTop: "25px",
}