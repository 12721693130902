import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Button from "components/common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { clearLabsTestData, getLabRequestId, getLabTests, postLabRequestId, postLabTest, postPutLabTest } from "redux/features/Labs/labsSlice";
import SelectField from "components/common/FormComponents/SelectField";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import {
  flexRow,
  flexColumn,
  justifyContentSpaceBetween,
} from "styles";
import { useFormik } from "formik";
import { orderLabModalSchema } from "validations/Modals/OrderLabModal";
import { useParams } from "react-router-dom";
import { getLabProvidersList } from "redux/features/Labs/LabProvidersSlice";

const OrderLabModal = ({
  toBeEditedLab,
  getLabs,
  setToBeEditedLab,
  labsIsLoading,
  options,
  setOpen,
  clearLabsData,
}) => {
  let dispatch = useDispatch();
  const { patientId } = useParams();
  const [labProvider, setLabProvider] = useState({});
  const { labRequestData, postLabTestIsAdded, postLabTestIsLoading, postLabRequestAdded } = useSelector((state) => state.labs);
  const { labProvidersDataOptions, labProvidersIsLoading } = useSelector((state) => state.labProviders);
  const { encounter } = useSelector((state) => state.encounter);
  const { userId } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      lab_request_id: toBeEditedLab?.id ?? "",
      cpt_codes: toBeEditedLab?.id ?? "",
      name: toBeEditedLab?.id ?? "",
      code: toBeEditedLab?.id ?? "",
    },
    enableReinitialize: true,
    validationSchema: orderLabModalSchema,
    onSubmit: (values) => {
      toBeEditedLab?.id ? dispatch(postPutLabTest(patientId, values, 'put', toBeEditedLab?.id)) : dispatch(postPutLabTest(patientId, values, 'post'))
    },
  });

  const onSelectLabProvider = (_, v) => {
    if (v?.value) {
      setLabProvider(v);
    } else {
      setLabProvider({});
      formik.setFieldValue("lab_request_id", "");
    }
  }

  const onLabsSelect = (e, v) => {
    if (v?.label) {
      formik.setFieldValue("cpt_codes", v?.cpt_codes);
      formik.setFieldValue("name", v?.label);
      formik.setFieldValue("code", v?.value);
    } else {
      formik.setFieldValue("cpt_codes", "");
      formik.setFieldValue("name", "");
      formik.setFieldValue("code", "");
    }
  }

  useEffect(() => {
    //request lab id only if it is not present against the patient and encounter id
    //also check that you are checking against the selected lab provider
    const filterLabReqByEncAndPatient = labRequestData?.find((v) => v?.lab_provider_id == labProvider?.value);
    if (!filterLabReqByEncAndPatient?.id) {
      if (labProvider?.value && patientId) {
        dispatch(postLabRequestId(patientId, {
          encounter_id: encounter?.id,
          user_id: userId,
          lab_provider_id: labProvider?.value,
        }));
      }
    } else {
      formik.setFieldValue("lab_request_id", filterLabReqByEncAndPatient?.id);
    }
  }, [labProvider, patientId, labRequestData?.length])

  useEffect(() => {
    postLabRequestAdded && dispatch(getLabRequestId(patientId, encounter?.id));
  }, [postLabRequestAdded]);

  useEffect(() => {
    if (postLabTestIsAdded) {
      dispatch(getLabTests(patientId, encounter?.id));
      dispatch(clearLabsTestData());
      setOpen(false);
      setToBeEditedLab({});
      setLabProvider({});
    }
  }, [postLabTestIsAdded]);

  useEffect(() => {
    const filterLabReqByEncAndPatient = labRequestData?.find((v) => (toBeEditedLab?.id ? v?.id == toBeEditedLab?.lab_request_id : v?.lab_provider_id == labProvider?.value));
    const findLabProvider = labProvidersDataOptions?.find((v) => v?.value == filterLabReqByEncAndPatient?.lab_provider_id);

    if (findLabProvider?.value) {
      setLabProvider(findLabProvider);
      formik.setFieldValue("lab_request_id", filterLabReqByEncAndPatient?.id);
    }
    if (toBeEditedLab?.id || toBeEditedLab?.value) {
      formik.setFieldValue("cpt_codes", toBeEditedLab?.cpt_codes);
      formik.setFieldValue("name", toBeEditedLab?.id ? toBeEditedLab?.name : toBeEditedLab?.label);
      formik.setFieldValue("code", toBeEditedLab?.id ? toBeEditedLab?.code : toBeEditedLab?.value);
    }
  }, [toBeEditedLab, labRequestData?.length, labProvidersDataOptions?.length]);

  useEffect(() => {
    return () => {
      dispatch(clearLabsTestData());
      setLabProvider({});
    };
  }, []);

  useEffect(() => {
    if (labProvidersDataOptions?.length) {
      onSelectLabProvider("", labProvidersDataOptions[0]);
    }
  }, [labProvidersDataOptions]);

  useEffect(() => {
    clearLabsData();
    return () => {
      clearLabsData()
    }
  }, [])

  // Load lab providers list on component mount.
  useEffect(() => {
    dispatch(getLabProvidersList());
  }, []);


  return (
    <Box>
      <Grid container sx={{ marginTop: "10px" }}>
        <Box
          sx={{
            ...flexRow,
            width: "100%",
            ...justifyContentSpaceBetween,
          }}
        >
          <Box
            sx={{
              ...flexColumn,
              margin: "5px",
              width: "50%",
              marginTop: labProvidersIsLoading ? "20px" : "0px",
            }}
          >
            <SkeletonWrapper
              multipleCount={2}
              condition={!false}
              props={[
                SkeletonPropsStyleGenerator("33%", "30px", "150px"),
                SkeletonPropsStyleGenerator("100%", "70px", "150px"),
              ]}
            >
              <SelectField
                key={labProvider?.value}
                cols={12}
                name="labs"
                label={"Labs"}
                value={labProvider?.value ? labProvider : {}}
                showError={labProvider?.value ? false : true}
                helperText={labProvider?.value ? "" : "Lab is required"}
                onChange={onSelectLabProvider}
                placeholder="Select Lab"
                options={labProvidersDataOptions}
                required
              />
            </SkeletonWrapper>
          </Box>
          <Box
            sx={{
              width: "50%",
              marginTop: labProvidersIsLoading ? "20px" : "0px",
            }}
          >
            <SkeletonWrapper
              multipleCount={2}
              condition={!false}
              props={[
                SkeletonPropsStyleGenerator("33%", "30px", "150px"),
                SkeletonPropsStyleGenerator("100%", "70px", "150px"),
              ]}
            >
              <SelectField
                required
                cols={12}
                defaultValue={toBeEditedLab?.id ? { label: toBeEditedLab?.name, value: toBeEditedLab?.code } : { label: toBeEditedLab?.label, value: toBeEditedLab?.value }}
                name="code"
                label={"Test Name"}
                placeholder="Choose test name"
                onSearch={getLabs}
                onClear={() =>
                  options?.length
                    ? (clearLabsData())
                    : null
                }
                formik={formik}
                openOnFocus={false}
                onChange={onLabsSelect}
                loading={labsIsLoading}
                options={options}
              />
            </SkeletonWrapper>
          </Box>
        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Button
          text={"Cancel"}
          sx={{ marginRight: "10px", width: "150px" }}
          border={"1px solid #272A40"}
          borderRadius="10px"
          backgroundColor="#FFFFFF"
          color="#272A40"
          btnColor="#272A40"
          onClick={() => {
            setOpen(false)
            setToBeEditedLab({});
            dispatch(clearLabsTestData());
            setLabProvider({});
          }}
        />
        <Button
          isLoading={postLabTestIsLoading}
          text={"Save Lab"}
          sx={{ width: "150px" }}
          borderRadius="10px"
          backgroundColor="#315FFF"
          color="#fff"
          btnColor="#fff"
          onClick={() => formik.submitForm()}
        />
      </Box>
    </Box>
  );
};

export default OrderLabModal;
