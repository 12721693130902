const startRecording = async (
  setRecordedUrl,
  setIsRecording,
  mediaStream,
  mediaRecorder,
  chunks
) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaStream.current = stream;
    mediaRecorder.current = new MediaRecorder(stream);
    mediaRecorder.current.ondataavailable = (e) => {
      if (e.data.size > 0) {
        chunks.current.push(e.data);
      }
    };
    mediaRecorder.current.onstop = () => {
      const recordedBlob = new Blob(chunks.current, { type: "audio/webm" });
      const url = URL.createObjectURL(recordedBlob);
      setRecordedUrl(url);
      setIsRecording(false); // Set recording status to false when recording stops
      chunks.current = [];
    };
    mediaRecorder.current.start();
    setIsRecording(true); // Set recording status to true when recording starts
  } catch (error) {
    console.error("Error accessing microphone:", error); // eslint-disable-line no-console
  }
};

const downloadRecording = (recordedUrl) => {
  const a = document.createElement("a");
  a.href = recordedUrl;
  a.download = "Audio recording";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const stopRecording = (mediaStream, mediaRecorder, setIsRecordingPaused, setIsRecording) => {
  if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
    mediaRecorder.current.stop();
    setIsRecordingPaused(false)
    setIsRecording(false)
  }
  if (mediaStream.current) {
    mediaStream.current.getTracks().forEach((track) => {
      track.stop();
    });
  }
};

const pauseRecording = (mediaStream, mediaRecorder, isRecordingPaused, setIsRecordingPaused) => {
  if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
    mediaRecorder.current.pause();
    setIsRecordingPaused(true)
  }
};

const resumeRecording = async (mediaStream, mediaRecorder, setIsRecording, setIsRecordingPaused) => {
  if (mediaRecorder.current) {
    mediaRecorder.current.resume();
    setIsRecording(true)
    setIsRecordingPaused(false)
  }
};


async function convertBlobToM4A(blobUrl) {
  // Fetch the Blob data
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  // Create a new Blob with the same data and set its type to 'audio/mp4'
  const m4aBlob = new Blob([blob], { type: "audio/mp4" });

  return m4aBlob;
}

export {
  startRecording, downloadRecording, stopRecording, pauseRecording, resumeRecording, convertBlobToM4A
}
