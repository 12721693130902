import { createSlice } from '@reduxjs/toolkit'

export const initialFamilyHistoryState = {
    familyHistorySuccess: null,
    familyHistoryData: [],
    familyHistoryIsLoading: false,
}

const name = "familyHistory"

export const familyHistorySlice = createSlice({
    name,
    initialState: initialFamilyHistoryState,
    reducers: {
        getFamilyHistory: (state, action) => {
            state.familyHistoryIsLoading = true
        },
        getFamilyHistorySuccess: (state, action) => {
            state.familyHistorySuccess = true
            state.familyHistoryIsLoading = false
            state.familyHistoryData = action.payload;
        },
        getFamilyHistoryFail: (state, action) => {
            state.familyHistorySuccess = false
            state.familyHistoryData = []
            state.familyHistoryIsLoading = false
        },
        clearFamilyHistoryData: (state, action) => {
            state.familyHistorySuccess = null
            state.familyHistoryData = []
            state.familyHistoryIsLoading = false
        }
    },
})

export const { getFamilyHistory, clearFamilyHistoryData } = familyHistorySlice.actions

export default familyHistorySlice.reducer

export const getFamilyHistoryList = (patientId) => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/patients/${patientId}/familyhistory/familyhistory_icd/`
    },
    type: "getFamilyHistory",
});