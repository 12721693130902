const headerStyle = {
  display: "flex",
  alignItems: "center",
};
const startImageStyle = {
  width: "20px",
  height: "20px",
  marginRight: "3px",
};
const closeButtonStyle = {
  width: "24px",
  height: "24px",
};

const chatSearchBarStyling = {
  marginTop: "10px",
};
const sendBtnStyle = {
  width: " 37px",
  height: "36px",
  background: "#246EFD",
  borderRadius: "100px",
  border: "none",
};

const chatBotTitle = (forSearch) => {
  return {
    top: "90px",
    zIndex: "999",
    padding: "10px",
    backgroundColor: "#FFFFFF",
    width: forSearch ? "100%" : "26%",
    maxWidth: forSearch ? "100%" : "23%",
    position: forSearch ? "static" : "fixed",
    borderTopLeftRadius: forSearch ? "10px" : "24px",
    borderTopRightRadius: forSearch ? "10px" : "24px",
  };
};

const drawerWrapper = () => {
  return {
    "& .MuiPaper-root.MuiPaper-elevation": {
      minWidth: "350px",
    },
  };
};

const drawerChatBotTitle = () => {
  return {
    maxWidth: "100%",
    width: "340px",
    position: "fixed",
    top: "75px",
    marginLeft: "10px",
    marginBottom: "10px",
    background: "#ffffff",
    paddingTop: "10px",
    height: "45px",
  };
};

const chatBotConversation = (theme, forSearch) => {
  return {
    flex: "1",
    top: "140px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    width: forSearch ? "100%" : "23%",
    maxWidth: forSearch ? "100%" : "23%",
    position: forSearch ? "static" : "fixed",
    maxHeight: forSearch ? "453px" : "78vh",
    minHeight: forSearch ? "453px" : "78vh",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",

    [`@media (min-width: 1024px) and (max-width:1201px)`]: {
      maxHeight: forSearch ? "453px" : "75vh",
      minHeight: forSearch ? "453px" : "75vh",
    },
    [`@media (min-width: 1200px) and (max-width:1400px)`]: {
      maxHeight: forSearch ? "453px" : "67vh",
      minHeight: forSearch ? "453px" : "67vh",
    },
    [`@media (min-width: 1401px) and (max-width:1780px)`]: {
      maxHeight: forSearch ? "453px" : "76vh",
      minHeight: forSearch ? "453px" : "76vh",
      borderBottomLeftRadius: forSearch ? "" : "",
      borderBottomRightRadius: forSearch ? "" : "",
    },
    [theme.breakpoints.up("xxl")]: {
      maxHeight: forSearch ? "453px" : "79vh",
      minHeight: forSearch ? "453px" : "79vh",
      borderBottomLeftRadius: forSearch ? "" : "",
      borderBottomRightRadius: forSearch ? "" : "",
    },
    [`@media (min-width: 1024px) and (min-height: 954px)`]: {
      minHeight: forSearch ? "453px" : "100% !important",
    },
    [`(min-height: 850px)`]: {
      minHeight: forSearch ? "453px" : "100% !important",
    },
  };
};
const drawerChatBotConversation = () => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "350px",
    overflow: "auto",
    marginTop: "120px",
    marginLeft: "10px",
  };
};

const chatBottomStyling = (forSearch) => {
  return {
    bottom: "10px",
    padding: "10px",
    mt: forSearch ? 0 : 2,
    backgroundColor: "#FFFFFF",
    width: forSearch ? "100%" : "23%",
    maxWidth: forSearch ? "100%" : "23%",
    position: forSearch ? "static" : "fixed",
    borderBottomLeftRadius: forSearch ? "10px" : "24px",
    borderBottomRightRadius: forSearch ? "10px" : "24px",
  };
};
const drawerChatBottomStyling = () => {
  return {
    maxWidth: "100%",
    width: "340px",
    marginLeft: "10px",
    marginRight: "10px",
    background: "#ffffff",
  };
};

export {
  drawerChatBottomStyling,
  chatBottomStyling,
  drawerChatBotConversation,
  chatBotConversation,
  drawerChatBotTitle,
  drawerWrapper,
  chatBotTitle,
  chatSearchBarStyling,
  closeButtonStyle,
  startImageStyle,
  headerStyle,
  sendBtnStyle
}