import { Box, Grid, useTheme } from "@mui/material";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import Toggler from "components/common/Toggler/Toggler";
import Text from "components/common/Typography/Text";
import { useFormik } from "formik";
import * as toast from "hooks/notify";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordUser,
  clearChangePassword,
} from "redux/features/PrivacySecurity/ChangePasswordSlice";
import {
  changePasswordBoxStyling,
  changePasswordButtonBoxStyling,
  changePasswordButtonStyling,
  mainPrivacySecurityBox,
  twoFactorAuthBoxStyling,
  twoFactorInnerBox,
  twoFactorParagraph,
  twoFactorTogglerBox,
  upperPasswordAuthenticationBox,
} from "styles/Settings/PrivacySecurity";
import { changePasswordSchema } from "validations/Settings/changePassword.schema";

const PrivacySecurity = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.auth);
  const {
    isChangePasswordLoading,
    changePasswordMessage,
    changePasswordSuccess,
  } = useSelector((state) => state.changePassword);

  useEffect(() => {
    dispatch(clearChangePassword());
  }, []);

  useEffect(() => {
    if (changePasswordMessage?.length > 1) {
      if (changePasswordSuccess) {
        toast.success(changePasswordMessage);
        formik.resetForm();
      } else if (changePasswordSuccess === false) {
        toast.error(changePasswordMessage);
      }
    }
    dispatch(clearChangePassword());
  }, [changePasswordMessage]);

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      values.user_id = userId;
      values.operation = "update_password";
      dispatch(changePasswordUser(values));
    },
  });

  return (
    <Box className="title-box" sx={mainPrivacySecurityBox}>
      <Text sx={{ marginBottom: "10px" }} variant="h1">
        Privacy and Security
      </Text>
      <Grid container spacing={1} className="change-password-box">
        <Grid item xs={12} sm={12} md={12}>
          <Box sx={upperPasswordAuthenticationBox}>
            <Box
              sx={{
                borderBottom: "1px solid #D9D9D9",
              }}
            >
              <Box
                sx={{
                  padding: "20px",
                }}
              >
                <Text variant="h3" color="blue">
                  Change Password
                </Text>
              </Box>
            </Box>
            <Grid container spacing={2} sx={{ padding: "15px 20px 0px 20px" }}>
              <InputField
                xs={12}
                sm={12}
                md={12}
                lg={6}
                formik={formik}
                type={"password"}
                name="old_password"
                label="Current password"
                placeholder={"Enter current password"}
                fontWeight="400"
              />
            </Grid>
            <Grid container spacing={2} sx={{ padding: "15px 20px 0px 20px" }}>
              <InputField
                xs={12}
                sm={12}
                md={12}
                lg={6}
                formik={formik}
                type={"password"}
                name="new_password"
                label="Enter new password"
                placeholder={"new password"}
                fontWeight="400"
              />
              <InputField
                xs={12}
                sm={12}
                md={11}
                lg={6}
                formik={formik}
                type={"password"}
                name="confirm_password"
                label="Re-enter new password"
                placeholder={"new password"}
                fontWeight="400"
              />
            </Grid>
            <Box sx={changePasswordButtonBoxStyling}>
              <Button
                isLoading={isChangePasswordLoading}
                id="change-password-button"
                text="Change Password"
                backgroundColor={theme.palette.common.blue}
                btnColor={theme.palette.common.white}
                fontWeight={"400"}
                borderRadius="12px"
                sx={{ marginTop: "5px", height: "38px", padding: "1px 20px" }}
                onClick={formik.handleSubmit}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrivacySecurity;
