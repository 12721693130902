import { createSlice } from "@reduxjs/toolkit";

export const initialMedicationsState = {
  medicationsSuccess: null,
  medicationsActiveData: [],
  medicationsIsLoading: false,
  medicationsInActiveData: [],
  addingMedicationInProgress: false,
  addedMedicationSuccessfully: false,
  deletingMedicationInProgress: false,
  deletedMedicationSuccessfully: false,
  updatingMedicationInProgress: false,
  updatedMedicationSuccessfully: false
};

const name = "medications";

export const medicationsSlice = createSlice({
  name,
  initialState: initialMedicationsState,
  reducers: {
    getMedications: (state) => {
      state.medicationsIsLoading = true;
    },
    getMedicationsSuccess: (state, action) => {
      state.medicationsSuccess = true;
      state.medicationsIsLoading = false;
      state.count = action.payload?.count;
      state.nextURL = action.payload?.next;

      const newActiveMeds = action.payload?.results?.filter(
        (s) => (s?.end_date === null || s?.end_date === null && s?.start_date === null)
      );
      state.medicationsActiveData = newActiveMeds?.length > 0 ? newActiveMeds : [];

      const newInActiveMeds = action.payload?.results?.filter((s) => s?.end_date !== null);
      state.medicationsInActiveData = newInActiveMeds?.length > 0 ? newInActiveMeds : [];
    },
    getMedicationsFail: (state) => {
      state.medicationsSuccess = false;
      state.medicationsActiveData = [];
      state.medicationsInActiveData = [];
      state.medicationsIsLoading = false;
    },
    //add medications
    addPatientMedications: (state) => {
      state.addingMedicationInProgress = true;
    },
    addPatientMedicationsSuccess: (state) => {
      state.addingMedicationInProgress = false;
      state.addedMedicationSuccessfully = true;
    },
    addPatientMedicationsFail: (state) => {
      state.addingMedicationInProgress = false;
    },
    //update medications
    updatePatientMedications: (state) => {
      state.updatingMedicationInProgress = true;
    },
    updatePatientMedicationsSuccess: (state) => {
      state.updatingMedicationInProgress = false;
      state.updatedMedicationSuccessfully = true;
    },
    updatePatientMedicationsFail: (state) => {
      state.updatingMedicationInProgress = false;
    },
    //delete medications
    deletePatientMedications: (state) => {
      state.deletingMedicationInProgress = true;
    },
    deletePatientMedicationsSuccess: (state) => {
      state.deletingMedicationInProgress = false;
      state.deletedMedicationSuccessfully = true;
    },
    deletePatientMedicationsFail: (state) => {
      state.deletingMedicationInProgress = false;
    },
    clearAddUpdateDeleteMedicationsData: (state) => {
      state.addingMedicationInProgress = false;
      state.addedMedicationSuccessfully = false;
      state.deletingMedicationInProgress = false;
      state.deletedMedicationSuccessfully = false;
      state.updatingMedicationInProgress = false;
      state.updatedMedicationSuccessfully = false;
    },
    clearMedicationsData: (state) => {
      state.medicationsSuccess = null;
      state.medicationsActiveData = [];
      state.medicationsInActiveData = [];
      state.medicationsIsLoading = false;
    },
  },
});

export const { getMedications, clearAddUpdateDeleteMedicationsData, clearMedicationsData } =
  medicationsSlice.actions;

export default medicationsSlice.reducer;

export const addPatientMedications = (patientId, data) => ({
  payload: {
    apiName: name,
    method: "post",
    data,
    url: `/api/patients/${patientId}/medications/`,
  },
  type: "addPatientMedications",
});

export const updatePatientMedications = (patientId, medicatrionId, payload) => ({
  payload: {
    apiName: name,
    method: "put",
    data: payload,
    url: `/api/patients/${patientId}/medications/${medicatrionId}/`,
  },
  type: "updatePatientMedications",
});

export const getPatientMedications = (patientId, nextURL = undefined) => ({

  payload: {
    apiName: name,
    method: "get",
    url: nextURL ? nextURL : `/api/patients/${patientId}/medications/`,
  },
  type: "getMedications",
});

export const deletePatientMedications = (patientId, id) => ({
  payload: {
    apiName: name,
    method: "delete",
    url: `/api/patients/${patientId}/medications/${id}/`,
  },
  type: "deletePatientMedications",
});
