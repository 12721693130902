import moment from "moment";

let lastVitalsRequiredOrder = ["datetime", "temperature", "blood_pressure", "heart_rate", "respiration_rate", "weight", "spo2"];

export function removeSnakeCasingAndCapitalize(str) {
    return str.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
}

/**
 * Added sorted keys according to required order in the beginning
 * Added the keys that were not in that order at the end
 * Added them to new Object
 * */
function reArrangeLastVitals(obj) {
    let sortedVitalsKeys = [
        ...lastVitalsRequiredOrder,
        ...Object.keys(obj).filter(key => !lastVitalsRequiredOrder.includes(key))]
    let sortedVitalsObj = sortedVitalsKeys.reduce((acc, key) => {
        acc[key] = obj[key]
        return acc
    }, {})
    return sortedVitalsObj;
}

function formatDate(date) {
    return moment.utc(`${date}`).format("YYYY-MM-DD");
}

function isDate(val) {
    // Check if the string matches the ISO 8601 format (e.g., "2024-09-12T12:24:34-05:00")
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:\d{2}|\s*Z)$/;
    if (!iso8601Regex.test(val)) {
        return val
    }

    // Check if the string is a valid date by parsing it
    const parsedDate = Date.parse(val);
    if ((!isNaN(parsedDate))) {
        return formatDate(val)
    }
}

export function convertJsonToMarkDown(data) {
    let mainMarkDownString = ``
    const addH3 = (key) => mainMarkDownString += `### ${removeSnakeCasingAndCapitalize(key)}:\n`
    const addList = (value, isLastIndex) => mainMarkDownString += `- ${value}${isLastIndex ? '\n\n' : '\n'}`
    const addKeyValueList = (key, value, isLastIndex) => mainMarkDownString += `- **${removeSnakeCasingAndCapitalize(key)}:** ${value}${isLastIndex ? '\n\n' : '\n'}`
    const addKeyValueString = (key, value) => mainMarkDownString += `**${removeSnakeCasingAndCapitalize(key)}:** ${value} \n\n`
    const addTable = (value) => {
        let tableHeader = ``
        let tableSpacer = ``
        for (const [key, _] of Object.entries(value?.[0])) {
            tableHeader += `|${removeSnakeCasingAndCapitalize(key)} `
            tableSpacer += `| --- `
        }
        mainMarkDownString += `${tableHeader} |\n${tableSpacer}|\n`
        for (let obj of value) {
            for (const [_, value] of Object.entries(obj)) {
                mainMarkDownString += `|${isDate(value)} `
            }
            mainMarkDownString += `|\n`
        }
    }

    function checkTypesAndAdd(key, value) {
        if (value == null) {
            addKeyValueString(key, "N/A")
            return;
        }

        if (key === "last_vitals") {
            // if key is last_vitals, reArrange the order of the keys
            value = reArrangeLastVitals(value)
        }

        if (typeof (value) === 'string') {
            if (key === "summary_paragraph") {
                // if the chief_complaint is already added, then skip adding it again
                if (data?.["chief_complaint"] !== undefined) return

                // if the chief_complaint is not added, then add it
                else addKeyValueString(key, value)
            }

            // To add smart paragraph right after ht chief_complaint
            if (key === "chief_complaint") {
                addKeyValueString(key, value)

                if (data?.["summary_paragraph"] && data?.["chief_complaint"] !== undefined ){
                    addKeyValueString("summary_paragraph", data["summary_paragraph"])
                }
            } else addKeyValueString(key, isDate(value, key))
        } else if (Array.isArray(value)) {
            addH3(key)
            if (typeof (value?.[0]) === "string") {
                value.forEach((val, index) => addList(isDate(val), index === value.length - 1))
            }

            // null has a type of object so we need to check if it is not null as well
            else if (typeof (value?.[0]) === "object" && value?.[0] !== null) {
                addTable(value)
            }
        }
        // We don't need to add a null check here because the null check at the beginning of the function will handle it.
        else if (typeof (value) === 'object') {
            addH3(key)
            Object.entries(value).forEach(([innerKey, innerValue], index, array) => {
                const isLastIndex = index === array.length - 1;
                addKeyValueList(innerKey, isDate(innerValue), isLastIndex)
            })
        }
    }

    for (const [key, value] of Object.entries(data)) {
        checkTypesAndAdd(key, value)
    }
    return mainMarkDownString;
}