import { createSlice } from "@reduxjs/toolkit";

export const initialCptCodeState = {
  cptCodes: [],
  cptCodesOptions: [],
  isCptCodeLoading: false,
};

const name = "cptCode";

export const cptCodeSlice = createSlice({
  name,
  initialState: initialCptCodeState,
  reducers: {
    getCptCode: (state, action) => {
      state.isCptCodeLoading = true;
    },
    getCptCodeSuccess: (state, action) => {
      state.isCptCodeLoading = false;
      state.cptCodes = action.payload.results?.length
        ? action.payload.results
        : state.cptCodes;
      state.cptCodesOptions = action.payload.results?.length
        ? action.payload.results.map((s) => ({
            label: s.cpt_code,
            value: s.id,
            description: s.description,
          }))
        : state.cptCodesOptions;
    },
    getCptCodeFail: (state, action) => {
      state.cptCodes = [];
      state.cptCodesOptions = [];
      state.isCptCodeLoading = false;
    },
    clearCptCode: (state, action) => {
      state.cptCodes = [];
      state.cptCodesOptions = [];
      state.isCptCodeLoading = false;
    },
  },
});

export const { getCptCode } = cptCodeSlice.actions;

export default cptCodeSlice.reducer;

export const getCptCodeList = (searchQuery) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/cptcodes/search/?q=${searchQuery}`,
  },
  type: "getCptCode",
});
