import { createSlice } from '@reduxjs/toolkit'

export const initialGetSpecificPatientState = {
    getSpecificPatientIsLoading: false,
    getSpecificPatientSuccess: null,
    getSpecificPatientData : [],
}

const name = "getSpecificPatient"

export const getSpecificPatientSlice = createSlice({
    name,
    initialState: initialGetSpecificPatientState,
    reducers: {
        getSpecificPatient: (state, action) => {
            state.getSpecificPatientIsLoading = true
        },
        getSpecificPatientSuccess: (state, action) => {
            state.getSpecificPatientIsLoading = false
            state.getSpecificPatientSuccess = true
            state.getSpecificPatientData = action.payload
        },
        getSpecificPatientFail: (state, action) => {
            state.getSpecificPatientIsLoading = false
            state.getSpecificPatientSuccess = false
            state.getSpecificPatientData = [] 
        },
        clearSpecificPatientData: (state, action) => {
            state.getSpecificPatientIsLoading = false
            state.getSpecificPatientSuccess = null
            state.getSpecificPatientData = [] 
        }
    },
})

export const { getSpecificPatient, clearSpecificPatientData } = getSpecificPatientSlice.actions

export default getSpecificPatientSlice.reducer

export const getSpecificPatientDetails = (patientId) => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/patients/${patientId}/`
    },
    type: "getSpecificPatient",
});

export const refreshPatientData = (patientId) => ({
    payload: {
        apiName: name,
        method: "POST",
        url: `/api/patients/refresh_smart_summary/?patient_id=${patientId}`
    },
    type: "refreshPatientData",
});