import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogActions, Fade, Slide, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { forwardRef } from "react";
import Icon from "../../Icons/Icon";
import Text from "../../Typography/Text";
import { alignItemsCenter, justifyContentCenter } from "styles";

const TransitionSlide = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const TransitionFade = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const CustomModal = ({
  sxDialog,
  titleIcon,
  sxDialogContent,
  sxPaperProps,
  actions,
  fade,
  noHeader,
  children,
  title,
  open,
  setOpen,
  width = "auto",
  maxWidth = "md",
  fullWidth = false,
  padding = "",
  className = "",
  onScroll,
}) => {
  const theme = useTheme();
  const handleClose = () => {
    setOpen && setOpen(false);
  };

  let titleStyling = {
    color: "white",
    marginTop: "0px",
    marginBottom: "0px",
    letterSpacing: "1px",
    display: "flex",
    ...justifyContentCenter,
    ...alignItemsCenter
  };

  return (
    <Dialog
      className="customDialog"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      TransitionComponent={fade ? TransitionFade : TransitionSlide}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{ ...sxDialog, ...{ width: width } }}
      data-testid={"modal-testid"}
      PaperProps={{
        style: {
          ...sxPaperProps,
        },
      }}
    >
      {noHeader ? null : (
        <DialogTitle id="scroll-dialog-title" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Text variant="bodyS" {...titleStyling}>
            <span data-testid={"modal-title"}>{title} </span>
            <span style={{ marginLeft: "4px" }}>{titleIcon && titleIcon}</span>
          </Text>
          <Box
            onClick={handleClose}
            sx={{ cursor: "pointer" }}
          >
            <Icon
              icon={<CloseIcon data-testid={"close-custom-modal-icon"} />}
              color={theme.palette.common.white}
            />
          </Box>
        </DialogTitle>
      )}
      <DialogContent
        className={className}
        sx={{
          minWidth: 500,
          padding: padding,
          ...sxDialogContent,
        }}
      >
        <Box p={1} data-testid={"modal-content"}>
          {children}
        </Box>
      </DialogContent>
      {actions && (
        <DialogActions
          sx={{
            borderTop: "1px solid #D9D9D9",
            padding: "10px 0px",
            display: "block",
          }}
        >
          {actions.map((action, index) => (
            <Box
              key={index}
              sx={{
                padding: "10px 20px",
              }}
            >
              {action}
            </Box>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CustomModal;
