import { Box } from "@mui/material";
import HorizontalTabs from "components/common/Tabs/HorizontalTabs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getVitalsList } from "redux/features/Vitals/VitalsSlice";
import LabResults from "./LabResults/LabResults";
import LabTrends from "./LabResults/LabTrends";
import VitalTrends from "./Vitals/VitalTrends";
import Vitals from "./Vitals/Vitals";
import { isOutOfRange } from "utils/isOutofRange";
import { sortWeights } from "assets/static";

const VitalLabMedWrapper = () => {
  let dispatch = useDispatch();
  let { patientId } = useParams();

  const [reportHeigh, setReportHeigh] = useState(0);
  const [isGraphDisplay, setIsGraphDisplay] = useState(1);
  const [activeSubTab, setActiveSubTab] = useState("Latest");
  const [formattedLabsData, setFormattedLabsData] = useState([]);
  const [childrenArrayIndex, setChildrenArrayIndex] = useState(0);

  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );
  const { labResults } = useSelector(
    (state) => state.resultsLabs
  );

  const getFormattedLabs = () => {
    let formattedData = [];
    labResults?.[0]?.lab_tests?.map((labTests) => {
      labTests?.lab_observations.map((details) => {
        formattedData.push({
          code: details?.name,
          val: `${details?.value} ${details?.unit}`,
          at_risk: details?.in_range,
          range: `${details?.reference_range} ${details?.unit}`,
        });
      });
    });

    // Sort formattedData according to sortWeights and prioritize eGFR values
    formattedData.sort((a, b) => {
      const defaultWeight = Object.keys(sortWeights).length;
      const indexA = sortWeights[a.code] ?? defaultWeight;
      const indexB = sortWeights[b.code] ?? defaultWeight;

      // Prioritize eGFR values
      if (a.code.startsWith("eGFR") && !b.code.startsWith("eGFR")) {
        return -1;
      }
      if (!a.code.startsWith("eGFR") && b.code.startsWith("eGFR")) {
        return 1;
      }

      return indexA - indexB;
    });

    setFormattedLabsData(formattedData);
    return;
  };

  useEffect(() => {
    getFormattedLabs();
  }, [labResults]);

  const patientName =
    getSpecificPatientData?.first_name +
    " " +
    getSpecificPatientData?.last_name;

  useEffect(() => {
    // Calculate the number of items with redLight
    const count = [...formattedLabsData].filter((data) =>
      isOutOfRange(data?.val, data?.range)
    )?.length;

    setReportHeigh(count);
  }, [formattedLabsData]);

  // to get the Vitals value
  useEffect(() => {
    dispatch(getVitalsList(patientId));
  }, [patientId]);

  const handleChildrenIndex = (index) => {
    setChildrenArrayIndex(index);
    setIsGraphDisplay(1);
  };

  const childrenArray = [
    {
      title: "Vitals",
      tab: activeSubTab === "Latest" ? <Vitals /> : <VitalTrends />,
    },
    {
      title: "Lab Results ",
      tab:
        activeSubTab === "Latest" ? (
          <LabResults
            labCollectionDate={labResults?.[0]?.datetime}
            formattedLabsData={formattedLabsData}
          />
        ) : (
          <LabTrends />
        ),
      right: true,
      isNumber: true,
      number: reportHeigh,
    },
  ];

  return (
    <Box
      style={{ position: "relative", height: "100%" }}
    >
      {(
        <Box style={{ height: "100%" }}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "24px",
              height: "100%",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <HorizontalTabs
              isDisplay={isGraphDisplay === 1}
              handleChildrenIndex={handleChildrenIndex}
              childrenArray={childrenArray}
              defaultValue={0}
              borderTab
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          top: "14px",
          right: "20px",
        }}
      >
        {childrenArrayIndex === 2 ? null : (
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                marginRight: "20px",
                fontSize: "14px",
                fontWeight: "600",
                cursor: "pointer",
                paddingBottom: "12px",
                borderBottom:
                  activeSubTab === "Latest" ? "2px solid #1976d2" : "",
              }}
              onClick={() => setActiveSubTab("Latest")}
            >
              Latest
            </Box>
            <Box
              sx={{
                marginRight: "10px",
                fontSize: "14px",
                fontWeight: "600",
                cursor: "pointer",
                paddingBottom: "12px",
                borderBottom:
                  activeSubTab === "Trends" ? "2px solid #1976d2" : "",
              }}
              data-testid="historical-tab-shifter-id"
              onClick={() => setActiveSubTab("Trends")}
            >
              {" "}
              Trends{" "}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VitalLabMedWrapper;
