import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Grid } from "@mui/material";

export const RadioField = ({ defaultValue, name, cols = 6, label, options, formik, onChange }) => {
    return (
        <Grid item xs={cols} sm={cols} md={cols} lg={cols} sx={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
            <FormControl fullWidth component="fieldset">
                {label ? (<FormLabel component="legend">{label}</FormLabel>) : ""}
                <RadioGroup
                    row
                    aria-label={label}
                    name={name}
                    onChange={onChange}
                    sx={{ width: "100%" }}
                    defaultValue={defaultValue}
                    {...formik?.getFieldProps(name)}
                >
                    {options.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                            sx={{ justifyContent: "center", flex: 1 }}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Grid>
    );
};