import { Box, Grid } from "@mui/material";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import SelectField from "components/common/FormComponents/SelectField";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { useEffect, useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AddPharmacy,
  ClearPharmacyStates,
  clearSearchPharmacyStates,
  deletePharmacy,
  getPharmacyList,
  getSearchPharmacy,
} from "redux/features/Pharmacy/PharmacySlice";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import { debounce } from "utils/debouncer";

const PharmacyModal = ({ setIsOpenModal }) => {
  let { patientId } = useParams();
  const dispatch = useDispatch();

  /* because of aborting of previous calls
  maintaining loading state in component*/
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [zipCode, setZipCode] = useState([]);
  const [searchedText, setSearchedText] = useState('');
  const [selectedPharmacy, setSelectedPharmacy] = useState(null);
  /* 
    state for dynamic count for the skeleton loaders
    on addition or deletion of the pharmacy
  */
  const [skeletonLoaderCount, setSkeletonLoaderCount] = useState(0);

  const { getSpecificPatientData = {} } = useSelector((state) => state.specificPatient);
  const { pharmacyList, pharmacyIsLoading } = useSelector((state) => state.pharmacy);

  // Use ref to track the delete state
  const isDeleting = useRef(false);
  const abortControllerRef = useRef(null);

  const {
    pharmacyAddedSuccess,
    searchedPharmaciesNextPage,
    searchedPharmaciesOptions,
  } = useSelector((state) => state.pharmacy);

  const handleSearch = debounce((value) => {
    let trimmedValue = value?.trim();
    if (trimmedValue?.length > 2) {
      setOptions([]);
      setSearchedText(trimmedValue);
      setLoading(true)
      searchPharmacies(trimmedValue, 1)
    } else {
      setSearchedText("");
    }
  }, 400);

  function searchPharmacies(text, page){

    // Cancel previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    dispatch(getSearchPharmacy(text, patientId, zipCode, page, abortController.signal));
  }

  const changeZipCode = debounce((e) => {
    // Clear the options of dropdown if the zip code is changed
    if (e.target.value !== zipCode) {
      setZipCode(e.target.value)
      setOptions([])
    }
  }, 500);

  const onPharmacySelect = (e, v) => {
    if (v?.label) {
      // to handle the duplicaiton process
      let pharmacyAlreadyExists = selectedPharmacy?.some((phar)=> Number(phar.dose_spot_id) === v?.id)
      if (!pharmacyAlreadyExists) {
        setSkeletonLoaderCount((prev) => prev + 1)
        dispatch(
          AddPharmacy(
            {
              "pharmacy_name": v?.label,
              "address": v?.value,
              "phone": v?.phone,
              "patient_id": patientId,
              "dose_spot_id": v?.id
            },
            patientId,
          )
        );
        isDeleting.current = false;  // Not deleting, adding a pharmacy
      }
      else toast.warning("Pharmacy already exists")
    }
  };

  const onDeletePharmacy = (pharmacyId) => {
    setSkeletonLoaderCount((prev) => prev - 1)
    isDeleting.current = true; // Marking as deleting
    setSelectedPharmacy(selectedPharmacy?.filter((pharmacy) => pharmacy?.id !== pharmacyId));
    dispatch(deletePharmacy(patientId, pharmacyId));
  };

  // fetch the pharmacy list
  useEffect(() => {
    dispatch(getPharmacyList(patientId));
  }, [patientId])

  useEffect(() => {
    if (pharmacyAddedSuccess) {
      dispatch(getPharmacyList(patientId));
      dispatch(ClearPharmacyStates());

      // Show toast based on whether we're deleting or adding
      if (isDeleting.current) {
        toast.success("Pharmacy Deleted");
      } else {
        toast.success("Pharmacy Added");
      }
    }
  }, [pharmacyAddedSuccess]);

  useEffect(() => {
    if (pharmacyList?.length) {
      setSelectedPharmacy(pharmacyList);
    }
  }, [pharmacyList]);

  useEffect(()=>{
    setSkeletonLoaderCount(selectedPharmacy?.length)
  },[selectedPharmacy])

  useEffect(()=>{
    setLoading(false)
    setOptions((prevState)=> [...prevState, ...searchedPharmaciesOptions])
  },[searchedPharmaciesOptions])

  useEffect(()=>{
    setZipCode(getSpecificPatientData?.zipcode)
  },[getSpecificPatientData])

  useEffect(() => {
    return () => {
      // options of the search should be cleared
      dispatch(clearSearchPharmacyStates());
    };
  }, []);

  return (
    <Box>
      <Box padding={2}>
        <Box sx={{ float: "right", marginBottom: 1 }}>
          <Box sx={{ marginLeft: 1 }}>
            <Text variant="bodyS" fontWeight={600}>
              Patient Zip Code:
            </Text>{" "}
            <Text variant="bodyS" fontWeight={400}>
              {getSpecificPatientData?.zipcode ?? ""}
            </Text>
          </Box>
        </Box>
        <Grid container spacing={2} >
          <SelectField
            cols={10}
            onSearch={handleSearch}
            onChange={onPharmacySelect}
            options={options}
            // Stop filtration of options.
            enableFiltering={false}
            loading={loading}
            placeholder="Search pharmacy"
            showMoreInLabel={{ show: true, value: "value" }}
            // Details for the infinite scroll in dropdown
            infiniteScrollDetails={{
              hasMoreToFetch: searchedText?.length > 2 ? !!searchedPharmaciesNextPage : null,
              fetchMore: () => searchPharmacies(searchedText, searchedPharmaciesNextPage),
            }}
          />
          <InputField
            sm={2}
            md={2}
            lg={2}
            xl={2}
            placeholder={"Zip Code"}
            onChange={(e) => changeZipCode(e)}
            defaultValue={getSpecificPatientData?.zipcode ?? ""}
          />
        </Grid>
        <Box paddingY={2} paddingX={2}>
          <SkeletonWrapper
            condition={!pharmacyIsLoading}
            // this count will be dynamic 
            multipleCount={skeletonLoaderCount}
            props={[SkeletonPropsStyleGenerator("100%", "44px", "150px")]}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Text fontWeight={600} variant="bodyS">
                  Pharmacy Name
                </Text>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Text fontWeight={600} variant="bodyS">
                  Address
                </Text>
              </Grid>
            </Grid>
            {selectedPharmacy?.length ? selectedPharmacy?.map((pharmacy, index) => (
              <Grid
                container
                spacing={2}
                paddingY={1.5}
                key={index}
                borderBottom={" 1px solid #ccc"}
              >
                <Grid item xs={12} sm={3}>
                  <Text variant="bodyS">{pharmacy?.pharmacy_name}</Text>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Text variant="bodyS">{pharmacy?.address}</Text>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <span onClick={() => onDeletePharmacy(pharmacy?.id)} style={{ cursor: "pointer" }}>
                      x
                    </span>
                  </Box>
                </Grid>
              </Grid>
            )) :
              <Grid
                container
                spacing={2}
                paddingY={1.5}
                borderBottom={" 1px solid #ccc"}
              >
                <Grid item xs={12} sm={12} textAlign={'center'}>
                  <Text center={true} variant="bodyS">No Pharmacies Found</Text>
                </Grid>
              </Grid>
            }
          </SkeletonWrapper>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Button
          text={"Close"}
          onClick={() => {
            isDeleting.current = false; // Reset delete flag
            setIsOpenModal && setIsOpenModal(false);
            dispatch(ClearPharmacyStates());
          }}
          sx={{ marginRight: "10px", width: "150px" }}
          border={"1px solid #272A40"}
          borderRadius="10px"
          backgroundColor="#FFFFFF"
          color="#272A40"
          btnColor="#272A40"
        />
      </Box>
    </Box>
  );
};

export default PharmacyModal;
