import { Box } from "@mui/material";
import Flex from "Layouts/Flex/Flex";
import { condtionalStyling } from "assets/static";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { memo, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";

const MessagesComponent = ({
  id,
  incoming,
  message,
  showLoader,
  forSearch,
}) => {
  const [conditionStyling, setConditionStyling] = useState(
    condtionalStyling(incoming)
  );

  return (
    <>
      <Flex
        justifyContent={conditionStyling?.justifyContent}
        marginTop="10px"
        marginBottom="10px"
      >
        <Box
          data-testid={id}
          sx={{
            width: "85%",
            borderRadius: "12px",
            padding: "7px 1px 7px 5px",
            marginRight: "10px",
          }}
        >
          <Box
            sx={{
              textAlign: conditionStyling?.textAlign,
              padding: "0px 12px 0px 0px",
            }}
          >
            <Text variant="bodyS">{conditionStyling?.name}</Text>
          </Box>
          <Box
            sx={{
              background: conditionStyling?.background,
              padding: "13px",
              borderRadius: conditionStyling?.borderRadius,
            }}
          >
            <SkeletonWrapper
              id={"saya-message-loading-id"}
              condition={!showLoader}
              props={[SkeletonPropsStyleGenerator("98%", "40px", "150px")]}
            >
              {incoming ? (
                <Text variant={forSearch ? `bodyS` : `bodyM`}>{message}</Text>
              ) : (
                <TypeAnimation
                  cursor={false}
                  sequence={[
                    `${
                      message === ""
                        ? "Sorry, I am unable to find requested data"
                        : message
                    }`,
                    50,
                  ]}
                  speed={85}
                  style={{
                    whiteSpace: "pre-line",
                    fontSize: forSearch ? "0.5rem" : "0.938rem",
                  }}
                />
                // ))
              )}
            </SkeletonWrapper>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default memo(MessagesComponent);
