import { Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import { memo } from "react";
import MainContainer from "./MainContainer";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

const DashboardLayout = styled("div")(({ theme }) => {
  return {
    display: "flex",
    "& .MuiAppBar-root": {
      zIndex: theme.zIndex.drawer + 1,
    },

    ".MuiAppBar-root .MuiTypography-root": {
      flexGrow: 1,
    },

    [theme.breakpoints.up("md")]: {
      "& .MuiDrawer-root": {
        width: "11%",
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& .MuiDrawer-root": {
        width: "4.2%",
      },
    },

    "& .MuiDrawer-root": {
      flexShrink: 0,
      position: "fixed",
    },

    [theme.breakpoints.down("md")]: {
      "& .MuiDrawer-paper": {
        width: "75px",
        marginLeft: "5px",
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& .MuiDrawer-paper": {
        width: "75px",
        marginLeft: "5px",
      },
    },
    "& .MuiDrawer-paper": {
      backgroundColor: "transparent !important",
      height: "100%",
      marginTop: "83px",
      marginLeft: "5px",
    },
    ".MuiDrawer-root  .MuiListItem-root": {
      display: "flex",
      alignItems: "center",
      paddingRight: theme.spacing(3),
    },
    ".MuiAutocomplete-hasPopupIcon.css-1koza9f-MuiAutocomplete-root .MuiOutlinedInput-root":
      {
        padding: "8.5px !important",
      },
    ".MuiDrawer-root  .MuiListItemIcon-root": {
      minWidth: theme.spacing(4),
      marginRight: theme.spacing(2),
      color: "#3245B4",
    },
    ".MuiDrawer-root  .MuiListItemText-primary": {
      fontWeight: 500,
      color: "#3245B4",
    },
    "& .MuiBox-root.MuiBox-height100": {
      height: "100%",
    },
    "& .MuiBox-root.MuiBox-sidebarExpanded": {
      width: theme.spacing(9),
      "& .MuiListItemText-primary": {
        display: "none",
      },
    },
    "& .MuiCheckbox-root:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiRadio-root:hover": {
      backgroundColor: "transparent",
    },
  };
});

const customStyles = {
  flexShrink: 0,
  overflow: "hidden",
  zIndex: "1200",
  "& .MuiDrawer-paper": {
    height: "calc(100% - 89px)",
    boxSizing: "border-box",
    borderRight: "none",
    transition: "width 0.3s ease-in-out",
  },
};

const MainLayout = ({ children, theme }) => {
  return (
    <DashboardLayout data-testid="layout-container" theme={theme}>
      <Drawer variant="permanent" anchor="left" sx={customStyles}>
        <Sidebar />
      </Drawer>
      <MainContainer marginLeft="93px" marginTop="90px">
        {children}
      </MainContainer>
      <Navbar />
    </DashboardLayout>
  );
};

export default memo(MainLayout);
