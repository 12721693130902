import {
  Box, Grid, IconButton, Menu, MenuItem, Paper,
  Skeleton,
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  TableSortLabel,
  Toolbar,
  useTheme
} from "@mui/material";
import UpperHeader from "./components/UpperHeader";
import InputField from "components/common/FormComponents/InputField";
import { FilterList } from "@mui/icons-material";
import { useInfiniteQuery } from "@tanstack/react-query";
import { fetchPatients } from "apiClients/patients";
import { useCallback, useEffect, useRef, useState } from "react";
import Button from "components/common/Button/Button";
import { debounce, DEBOUNCE_DELAY } from "utils/debouncer";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Text from "components/common/Typography/Text";
import { useDispatch, useSelector } from "react-redux";
import { getAccountID } from "redux/features/Auth/loginSlice";

const PatientDashboard = () => {
  const columns = [
    { key: "given_names", label: "First name", format: (value) => value.first_names[0] || "NA" },
    { key: "last_name", label: "Last name" },
    { key: "birthdate", label: "Birthdate" },
    { key: "address", label: "Address" },
    { key: "zipcode", label: "Zipcode" },
    { key: "last_encounter", label: "Last encounter", format: (value) => value ? moment(value).format("YYYY-MM-DD") : "-", sortable: false },
    { key: "ckd_status", label: "CKD Status", format: (value) => value.join(', ') || "-" },
    { key: "emr_id", label: "EMR ID" },
  ];

  const theme = useTheme();
  const navigate = useNavigate();
  const [lastPatient, setLastPatient] = useState(null);
  const [filterType, setFilterType] = useState(columns.find(column => column.key === "last_name"));
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState({ sort: { field: "last_name", order: "asc" } });
  const { account_id } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["patients", options],
    queryFn: async (data) => fetchPatients(options, data),
    getNextPageParam: (lastPage) => lastPage.next,
  });

  const observer = useRef(new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      // Fetch more data or perform any action when the element is in view
      fetchNextPage();
    }
  },
    { threshold: 0.8 }
  ));

  useEffect(() => {
    const currentObserver = observer.current;
    const currentRef = lastPatient;

    if (currentRef) {
      currentObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        currentObserver.unobserve(currentRef);
      }
    };
  }, [lastPatient]);

  const search = debounce((newVal) => {
    if (newVal) {
      setOptions(prev => ({ ...prev, filter: { field: filterType.key, operator: "contains", value: newVal } }));
    } else {
      setOptions(prev => ({ ...prev, filter: null }));
    }
  }, DEBOUNCE_DELAY);

  const _visibleData = () => {
    return data?.pages.reduce((acc, curr) => {
      return [...acc, ...curr.results];
    }, []) ?? {};
  }

  const onSort = (field) => {
    const order = options.sort.field === field && options.sort.order === "asc" ? "desc" : "asc";

    setOptions({ ...options, sort: { field, order: order } });
  }

  const onFilterChange = (column) => {
    setFilterType(column);
    if (options.filter?.value) {
      // clear the filter if it exists
      setOptions({ ...options, filter: null });
      setSearchTerm("");
    }
  }

  const onSearch = useCallback((e) => {
    setSearchTerm(e.target.value);
    search(e.target.value);
  }, [setSearchTerm, search]);

  const visibleData = _visibleData();

  // needed for displaying the loggedin user profile photo
  useEffect(() => {
    dispatch(getAccountID());
  }, [dispatch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UpperHeader title="All Patients" />
        <Paper sx={{ borderRadius: "24px", minHeight: "600px", background: theme.palette.background, overflow: "hidden" }}>
          <Toolbar>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <Text sx={{ display: "flex", alignItems: "center" }} variant="bodyS">{visibleData.length && `${visibleData.length} of ${data?.pages[0]?.count}`}</Text>
              <InputField
                onChange={onSearch}
                cols={2}
                inputLabel={`Filter by ${filterType?.label?.toLowerCase()}`}
                iconDetails={{ allowIcon: true, IconComponent: IconFilterMenu, iconProps: { columns, onFilterChange } }}
                positionRight
                value={searchTerm}
              />
            </Box>
          </Toolbar>
          <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table size="medium" stickyHeader sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.key} align={column.align || "left"}>
                      {column.sortable === false && column.label}
                      {column.sortable == null && <TableSortLabel
                        active={options.sort.field === column.key}
                        direction={options.sort.field === column.key ? options.sort.order : "asc"}
                        onClick={() => onSort(column.key)}
                      >
                        {column.label}
                      </TableSortLabel>}
                    </TableCell>
                  ))}
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(isFetching && !hasNextPage) ? (
                  TableBodyLoader(columns)
                ) : <>
                  {visibleData?.map((patient, patientIdx) => (
                    <TableRow key={`${patient.id}-${patientIdx}`}>
                      {columns.map((column) => (
                        <TableCell key={column.key} align={column.align || "left"}>
                          {column.format ? column.format(patient[column.key]) : patient[column.key] ?? "-"}
                        </TableCell>
                      ))}
                      <TableCell align="center">
                        <Button
                          backgroundColor={theme?.palette?.common?.blue}
                          btnColor={theme?.palette?.common?.white}
                          sx={{ minWidth: "10em" }}
                          text={"View Patient"}
                          onClick={() => navigate(`/patients/${patient.id}`)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow ref={setLastPatient}><TableCell sx={{ borderBottom: "none" }} colSpan={columns.length + 1} align="center">{isFetching ? "Loading more..." : ""}</TableCell></TableRow>
                </>}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PatientDashboard;


const TableBodyLoader = (columns) => {
  return (<>
    {Array(10).fill("").map((_, index) => (
      <TableRow key={index}>
        {columns.map((column) => (
          <TableCell key={column.key}>
            <Skeleton variant="rounded" sx={{ height: "30px" }} />
          </TableCell>
        ))}
        {/* Add 1 more for the action column */}
        <TableCell> <Skeleton variant="rounded" sx={{ height: "30px" }} /></TableCell>
      </TableRow>
    ))}
  </>);
}

const IconFilterMenu = ({ columns = [], onFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <>
    <IconButton
      onClick={handleClick}
      size="small"
      sx={{ ml: 2 }}
      aria-controls={open ? 'account-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
    >
      <FilterList />
    </IconButton>
    <Menu
      id="account-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
    >
      {columns.map((column) => (
        <MenuItem key={column.key} onClick={() => onFilterChange(column)}>
          {column.label}
        </MenuItem>
      ))}
    </Menu>
  </>
}
