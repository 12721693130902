import { createSlice } from "@reduxjs/toolkit";

export const initialOverviewState = {
    overviewPatientSeenData: null,
    isOverviewPatientSeenLoading: false,
    performance_ind_data: null,
    overview_key_ind_data: null,
    performance_ind_data_loading: false,
    overview_key_ind_loading: false,
};

const name = "overview";

export const OverviewSlice = createSlice({
    name,
    initialState: initialOverviewState,
    reducers: {
        //get Overview Patients Seen
        getOverviewPatientSeen: (state, action) => {
            state.isOverviewPatientSeenLoading = true;
        },
        getOverviewPatientSeenSuccess: (state, { payload }) => {
            state.isOverviewPatientSeenLoading = false;
            state.overviewPatientSeenData = payload
        },
        getOverviewPatientSeenFail: (state, action) => {
            state.isOverviewPatientSeenLoading = false;
        },

        //get performance indicators
        getPerformanceIndicators: (state, action) => {
            state.performance_ind_data_loading = true;
        },
        getPerformanceIndicatorsSuccess: (state, { payload }) => {
            state.performance_ind_data_loading = false;
            state.performance_ind_data = payload
        },
        getPerformanceIndicatorsFail: (state, action) => {
            state.performance_ind_data_loading = false;
        },

        //get Overview key indicators
        getOverviewKeyIndicators: (state, action) => {
            state.overview_key_ind_loading = true;
        },
        getOverviewKeyIndicatorsSuccess: (state, { payload }) => {
            state.overview_key_ind_loading = false;
            state.overview_key_ind_data = payload
        },
        getOverviewKeyIndicatorsFail: (state, action) => {
            state.overview_key_ind_loading = false;
        },

        //clear states
        clearOverviewStates: (state) => {
            state.performance_ind_data_loading = false;
            state.overview_key_ind_loading = false;
            state.performance_ind_data = {}
            state.overview_key_ind_data = {}
            state.overviewPatientSeenData = {}
            state.isOverviewPatientSeenLoading = false;
        },
    },
});

export const { clearOverviewStates } = OverviewSlice.actions;

export default OverviewSlice.reducer;

export const getOverviewPatientSeen = () => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/encounters/patient_seen_graph/`,
    },
    type: "getOverviewPatientSeen",
});

export const getPerformanceIndicators = () => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/encounters/performance_indicators/`,
    },
    type: "getPerformanceIndicators",
});

export const getOverviewKeyIndicators = () => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/encounters/overview_key_indicator/`,
    },
    type: "getOverviewKeyIndicators",
});
