import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const GenerateBreadcrumbs = ({ id }) => {
  // Get patient data for header
  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );

  const breadcrumbsData = [
    {
      pattern: /^\/$/,
      url: "/",
      crumbsData: [{ label: "Nephrolytics" }],
    },
    {
      pattern: /^\/appointments$/,
      url: "/appointments",
      crumbsData: [
        { label: "Nephrolytics", url: "/" },
        { label: "All appointments" },
      ],
    },
    {
      pattern: /^\/patients$/,
      url: "/patients",
      crumbsData: [
        { label: "Nephrolytics", url: "/" },
        { label: "All patients" },
      ],
    },
    {
      pattern: /^\/settings\?tab=1$/,
      url: "/settings?tab=1",
      crumbsData: [
        { label: "Nephrolytics", url: "/" },
        { label: "Profile Settings" },
      ],
    },
    {
      pattern: /^\/settings\?tab=2$/,
      url: "/settings?tab=2",
      crumbsData: [
        { label: "Nephrolytics", url: "/" },
        { label: "My Team" },
      ],
    },
    {
      pattern: /^\/settings\?tab=3$/,
      url: "/settings?tab=3",
      crumbsData: [
        { label: "Nephrolytics", url: "/" },
        { label: "Privacy and Security" },
      ],
    },
    {
      pattern: /^\/schedule\/patient\/[^/]+\/appointment\/[^/]+$/,
      crumbsData: [
        { label: "Nephrolytics", url: "/" },
        { label: "All appointments", url: "/appointments" },
        {
          label: `${getSpecificPatientData?.first_name ?? ""} ${getSpecificPatientData?.last_name ?? ""}`,
        },
      ],
    },
    {
      pattern: /^\/patients\/[^/]+$/,
      crumbsData: [
        { label: "Nephrolytics", url: "/" },
        { label: "All patients", url: "/patients" },
        {
          label: `${getSpecificPatientData?.first_name ?? ""} ${getSpecificPatientData?.last_name ?? ""}`,
        },
      ],
    },
  ];

  const findClosestMatch = (url) => {
    const closestMatch = breadcrumbsData.find((item) => item.pattern.test(url));
    // If no match is found, return the first item in the breadcrumbsData array
    return closestMatch || breadcrumbsData[0];
  };

  const breadCrumbs = findClosestMatch(
    window.location.pathname + window.location.search
  );

  return (
    <Breadcrumbs
      data-testid={id}
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {breadCrumbs?.crumbsData?.map((crumb, index) => (
        <Link
          key={index}
          color="inherit"
          style={{
            textDecoration: "none",
            color: index !== breadCrumbs?.crumbsData.length - 1 ? "#246EFD" : "#2D3748",
          }}
          to={
            index === 0
              ? "/"
              : crumb?.url ?? "#"
          }
        >
          <span
            style={{ borderBottom: "1px solid transparent" }}
            onMouseEnter={(e) =>
              (e.target.style.borderBottom = "1px solid #000")
            }
            onMouseLeave={(e) =>
              (e.target.style.borderBottom = "1px solid transparent")
            }
          >
            {crumb.label}
          </span>
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default GenerateBreadcrumbs;
