import { Box, useTheme } from "@mui/material";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { useNavigate } from "react-router-dom";
import elipses from "utils/ellipsis";
import circleleftarrow from "../../../assets/svg/colored/circleleftarrow.svg";
import correct from "../../../assets/svg/colored/correct.svg";
import correctBox from "../../../assets/svg/colored/correctBox.svg";
import wrong from "../../../assets/svg/colored/wrong.svg";
import { checkNullOrUndefinedString } from "utils/helper";
import AvatarPic from "../../../assets/images/profileAvatar.png";

const ListViewSchedules = ({
  id,
  time,
  status,
  img,
  name,
  disease,
  description,
  lastVisitDate,
  isActive,
  patientId,
  appointmentId,
}) => {
  const navigate = useNavigate();
  let theme = useTheme();
  const [viewWidth, setViewWidth] = useState(window.innerWidth);
  const [statusImg, setStatusImg] = useState("");

  // handling checkin-status images
  useEffect(() => {
    // checking all the status in lowercase
    if (status.toLowerCase() == "checked out") {
      setStatusImg(circleleftarrow);
    } else if (status.toLowerCase() == "cancelled") {
      setStatusImg(wrong);
    } else if (status.toLowerCase() == "scheduled") {
      setStatusImg(correctBox);
    } else {
      setStatusImg(correct);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setViewWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      id={`active`}
      onClick={() =>
        navigate(`/schedule/patient/${patientId}/appointment/${appointmentId}`)
      }
      sx={{
        cursor: "pointer",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        display: "flex",
        width: "99%",
        padding: "15px",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        margin: "0 auto 15px auto",
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
        transition: "scale 300ms",
        ...(isActive
          ? {
              border: "1px solid #1344f1",
              boxShadow: "0px 0px 14px 0px #ABBEFF",
              scale: 1.01,
              height: viewWidth < 992 ? "auto" : "90px",
            }
          : { height: viewWidth < 992 ? "auto" : "80px" }),
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", flex: 2 }}>
        <Box
          sx={{
            maxWidth: "100px",
            marginRight: "10px",
            height: "48px",
          }}
        >
          <img
            src={AvatarPic}
            alt=""
            style={{
              marginRight: "10px",
              borderRadius: "100%",
              width: "48px",
              height: "48px",
            }}
          />
        </Box>
        <Box
          sx={{
            maxWidth: "200px",
            marginRight: "10px",
            width: "fit-content",
          }}
        >
          <Text variant={isActive ? "h3" : "bodyS"}>
            {moment(time).format("H:mm")}
          </Text>
        </Box>
        <Box
          sx={{
            maxWidth: "200px",
            marginRight: "10px",
            width: "fit-content",
          }}
        >
          <Text
            variant="bodyS"
            sx={{
              fontWeight: isActive ? "500" : "400",
              color: isActive
                ? theme.palette.common.blue
                : theme.palette.common.black,
            }}
          >
            {checkNullOrUndefinedString(name)}
          </Text>
        </Box>
        <Box
          sx={{
            maxWidth: "200px",
            marginRight: "10px",
            width: "fit-content",
          }}
        >
          <Text variant="bodyS">{disease}</Text>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          marginRight: "10px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          flex: 2,
        }}
      >
        <Text variant="h4">{elipses(description, 230)}</Text>
      </Box>
      <Box
        sx={{
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flex: 1,
          flexWrap: viewWidth < 992 ? "wrap" : "nowrap",
        }}
      >
        <Box
          sx={{
            maxWidth: "200px",
            marginRight: "10px",
            width: "max-content",
            marginBottom: viewWidth < 992 ? "10px" : "0px",
          }}
        >
          <Text variant={isActive ? "h3" : "h4"}>
            {lastVisitDate ? moment(lastVisitDate).format("YYYY-MM-DD") : "N/A"}
          </Text>
        </Box>
        <Box
          sx={{
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
            maxWidth: "200px",
            width: "max-content",
          }}
        >
          <img src={statusImg} className="status-image" alt="" />
          <Text
            variant="h4"
            fontWeight="400"
            marginLeft="10px"
            sx={{ minWidth: "80px" }}
          >
            <span 
              style={{ textTransform: "capitalize" }}
            >{status}</span>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
export default memo(ListViewSchedules);
