import { createSlice } from "@reduxjs/toolkit";

export const initialUserState = {
  isUserLoading: false,
  isInviteLoading: false,
  isAddTeamMemberLoading: false,
  isUserDeleteLoading: false,
  userDeleteSuccess: false,
  addTeamMemberSuccess: false,
  inviteSentSuccess: false,
  usersList: [],
};

const name = "MyTeams";

export const userSlice = createSlice({
  name,
  initialState: initialUserState,
  reducers: {
    getUsers: (state, action) => {
      state.isUserLoading = true;
    },
    sendInvite: (state, action) => {
      state.isInviteLoading = true;
    },
    addTeamMember: (state, action) => {
      state.isAddTeamMemberLoading = true;
    },
    deleteUser: (state, action) => {
      state.isUserDeleteLoading = true;
    },
    addTeamMemberSuccess: (state, action) => {
      state.isAddTeamMemberLoading = false;
      state.addTeamMemberSuccess = true;
    },
    sendInviteSuccess: (state, action) => {
      state.isInviteLoading = false;
      state.inviteSentSuccess = true;
    },
    getUsersSuccess: (state, action) => {
      state.isUserLoading = false;
      state.usersList = action.payload;
    },
    deleteUserSuccess: (state, action) => {
      state.isUserDeleteLoading = false;
      state.userDeleteSuccess = true;
    },
    getUsersFail: (state, action) => {
      state.isUserLoading = false;
      state.usersList = [];
    },
    sendInviteFail: (state, action) => {
      state.isInviteLoading = false;
      state.inviteSentSuccess = false;
    },
    deleteUserFail: (state, action) => {
      state.isUserDeleteLoading = false;
    },
    addTeamMemberFail: (state, action) => {
      state.isAddTeamMemberLoading = false;
    },
    clearUserStatuses: (state) => {
      state.isUserLoading = false;
      state.isUserDeleteLoading = false;
      state.userDeleteSuccess = false;
    },
    softClearAddTeamStatuses: (state) => {
      state.isAddTeamMemberLoading = false;
      state.addTeamMemberSuccess = false;
      state.isInviteLoading = false;
      state.inviteSentSuccess = false;
    },
  },
});

export const {
  getUsers,
  clearUserStatuses,
  addTeamMemberSuccess,
  softClearAddTeamStatuses,
} = userSlice.actions;

export default userSlice.reducer;

export const getUsersList = (query) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/accounts/${query}/users/`,
  },
  type: "getUsers",
});

export const deleteUserAPI = (query) => ({
  payload: {
    apiName: name,
    method: "delete",
    url: `/api/users/${query}/`,
  },
  type: "deleteUser",
});

export const addTeamMemberAPI = (body) => ({
  payload: {
    apiName: name,
    method: "post",
    data: body,
    url: `/api/users/api_create_team_member/`,
  },
  type: "addTeamMember",
});

export const sendInviteAPI = (body) => ({
  payload: {
    apiName: name,
    method: "post",
    data: body,
    url: `/api/send-invitation/`,
  },
  type: "sendInvite",
});
