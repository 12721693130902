import { useContext } from "react";
import { Box, useTheme } from "@mui/material";
import MainLayoutContext from "contexts/MainLayoutContext";
import { getMainContainerBoxWidth } from "styles/AppContainer/MainContainerStyle";
import { useLocation } from "react-router-dom";

const MainContainer = ({ children, marginLeft, marginTop }) => {
  let theme = useTheme();
  const { title } = useContext(MainLayoutContext) || "";
  const { pathname } = useLocation();
  return (
    <Box
      className={title}
      sx={{
        minHeight: "50vh",
        marginTop: marginTop,
        marginLeft: marginLeft,
        marginBottom: "20px",
        maxWidth: "calc(100vw - 6%)",
        transition: (theme) =>
          theme.transitions.create("margin-left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        ...getMainContainerBoxWidth(theme, pathname),
      }}
    >
      {children}
    </Box>
  );
};

export default MainContainer;
