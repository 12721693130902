import MainLayout from "Layouts/AppContainer/Layout";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
const PrivateRoute = ({ theme }) => {
  const { loggedIn } = useSelector(state => state.auth);
  let location = useLocation();

  if (!loggedIn) {
    return <Navigate to={'/login'} />
  }

  return (
    <MainLayout theme={theme} >
      <Outlet />
    </MainLayout>
  )
};

export default PrivateRoute;