import TabPill from "components/common/Tabs/TabPill";
import { useSearchParams } from "react-router-dom";
import MyTeam from "./MyTeam";
import PrivacySecurity from "./PrivacySecurity";
import ProfileSettings from "./ProfileSettings";
import { useEffect, useState } from "react";

const SettingsWrapper = () => {
  let [queryParameters] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);

  let tabsData = [
    {
      title: "Profile Settings",
      tab: <ProfileSettings />,
      value: 0,
    },
    {
      title: "My Team",
      tab: <MyTeam />,
      value: 1,
    },
    {
      title: "Privacy and Security",
      tab: <PrivacySecurity />,
      value: 2,
    },
  ];

  useEffect(() => {
    const tabValue = Number(queryParameters.get("tab")) - 1;
    if (tabValue >= 0 && tabValue < tabsData.length) {
      setTabIndex(tabValue);
    } else {
      setTabIndex(0);
    }
  }, [queryParameters]);

  return (
    <TabPill
      childrenArray={tabsData}
      isSettings={true}
      defaultValue={tabIndex}
      tabSx={{ justifyContent: "flex-start" }}
    />
  );
};

export default SettingsWrapper;
