import { forwardRef } from "react";

export const ThreePeople = forwardRef((props, ref) => {
    return (
        <svg ref={ref} {...props} width="24" height="24" viewBox="0 0 52 53" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8904 25.7058C7.69179 25.7058 5.08972 23.1274 5.08972 19.9591C5.08972 16.7904 7.69179 14.2119 10.8904 14.2119C14.0887 14.2119 16.6903 16.79 16.6903 19.9591C16.6903 23.1278 14.0887 25.7058 10.8904 25.7058ZM10.8904 15.8248C8.58093 15.8248 6.70262 17.6795 6.70262 19.9591C6.70262 22.2382 8.58093 24.0929 10.8904 24.0929C13.1991 24.0929 15.0774 22.2382 15.0774 19.9591C15.0774 17.6795 13.1991 15.8248 10.8904 15.8248Z" />
            <path d="M13.224 41.6182H3.10788C1.94545 41.6182 1 40.6786 1 39.5237V36.8858C1 31.4855 5.43667 27.0918 10.8905 27.0918C13.0353 27.0918 15.0771 27.7612 16.7931 29.0272C17.1515 29.2918 17.2279 29.7966 16.9633 30.1554C16.6986 30.5133 16.1942 30.5897 15.8355 30.3255C14.3986 29.2654 12.6888 28.7047 10.8905 28.7047C6.3262 28.7047 2.6129 32.3751 2.6129 36.8858V39.5237C2.6129 39.7891 2.83499 40.0053 3.10788 40.0053H13.2244C13.6693 40.0053 14.0308 40.3664 14.0308 40.8117C14.0308 41.2567 13.6693 41.6182 13.224 41.6182Z" />
            <path d="M48.8921 41.0074H38.776C38.331 41.0074 37.9695 40.6463 37.9695 40.201C37.9695 39.756 38.331 39.3945 38.776 39.3945H48.8925C49.1653 39.3945 49.3874 39.1787 49.3874 38.9133V36.2754C49.3874 31.7639 45.6741 28.0943 41.1099 28.0943C39.2036 28.0943 37.3426 28.7508 35.8695 29.9431C35.5238 30.2231 35.0162 30.1699 34.7355 29.8238C34.4555 29.4777 34.5082 28.9697 34.8552 28.6893C36.6142 27.2658 38.8354 26.4814 41.1099 26.4814C46.5633 26.4814 50.9999 30.8748 50.9999 36.2754V38.9133C50.9999 40.0679 50.0545 41.0074 48.8921 41.0074Z" />
            <path d="M41.1096 25.3074C37.9114 25.3074 35.3097 22.7293 35.3097 19.5606C35.3097 16.3919 37.9114 13.8135 41.1096 13.8135C44.3082 13.8135 46.9103 16.3915 46.9103 19.5606C46.9103 22.7297 44.3078 25.3074 41.1096 25.3074ZM41.1096 15.4264C38.8009 15.4264 36.9226 17.2811 36.9226 19.5606C36.9226 21.8402 38.8009 23.6945 41.1096 23.6945C43.4191 23.6945 45.2974 21.8402 45.2974 19.5606C45.2974 17.2811 43.4187 15.4264 41.1096 15.4264Z" />
            <path d="M36.9453 44.2418H15.0546C13.6004 44.2418 12.4175 43.0676 12.4175 41.624V37.9138C12.4175 30.499 18.51 24.4668 26 24.4668C33.49 24.4668 39.5825 30.499 39.5825 37.9138V41.624C39.5825 43.0676 38.3996 44.2418 36.9453 44.2418ZM26 26.0801C19.3999 26.0801 14.0304 31.389 14.0304 37.9142V41.6244C14.0304 42.1784 14.4899 42.6293 15.0546 42.6293H36.945C37.5096 42.6293 37.9692 42.1784 37.9692 41.6244V37.9142C37.9696 31.389 32.6 26.0801 26 26.0801Z" />
            <path d="M25.7196 23.5244C21.6102 23.5244 18.2662 20.2123 18.2662 16.1407C18.2662 12.0695 21.6098 8.75781 25.7196 8.75781C29.8298 8.75781 33.1734 12.0699 33.1734 16.1411C33.1734 20.2127 29.8294 23.5244 25.7196 23.5244ZM25.7196 10.3707C22.4993 10.3707 19.8791 12.9594 19.8791 16.1411C19.8791 19.3232 22.4993 21.9119 25.7196 21.9119C28.9403 21.9119 31.5605 19.3232 31.5605 16.1411C31.5605 12.9594 28.9403 10.3707 25.7196 10.3707Z" />
        </svg>

    );
});