import { Box } from "@mui/material";
import Text from "components/common/Typography/Text";
import Button from "components/common/Button/Button";
import Editor from "components/Editor/Editor";

const PastVisitModalComponent = ({ setOtherEncounter, calledFromAppts, setOpenEndVisit, data }) => {
  return (
    <>
    <Box sx={{ display: "flex", gap: 4}} >
      <Box>
        <Box>
          <Box>
            <Text variant="propertyLabel">Created by</Text>
          </Box>
          <Text variant="bodyXs">---</Text>
        </Box>
      </Box>
      <Box >
          <Box>
            <Text variant="propertyLabel">Reason for Visit</Text>
          </Box>
          <Text variant="bodyXs"> {data?.visit_reason ?? "---"} </Text>
        </Box>
    </Box>
      <Box paddingY={3}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Text variant="h2" >Soap Notes</Text>
          {
            !calledFromAppts && data?.sign_close_ind === "r" ? (
              <Button
                text="Review Notes"
                color="#ffffff"
                backgroundColor="#1344f1"
                fontSize={'12px'}
                btnColor="#ffffff"
                borderRadius="10px"
                sx={{ marginTop: "10px", width: "107px", height: "30px" }}
                onClick={() => {
                  setOtherEncounter(data)
                  setOpenEndVisit(true)
                }}
              />
            ) : null
          }
        </Box>
        <AccordionNotes
          marginTop="20px"
          markDownFormat={data?.plan_note ? true : false}
          notes={data?.plan_note ? data?.plan_note : data?.soap_notes} />
      </Box>

    </>
  );
};

const AccordionNotes = ({ notes = null, marginTop, markDownFormat }) => {
  return (
    <Box
      className="cus-textFocus"
      sx={{
        borderRadius: "11px",
        background: "white",
        marginTop: marginTop,
      }}
    >
      {
        notes == "" || notes == null ? (
          <Box marginY={4}>
            <Text variant="bodyS">No Data Found.</Text>
          </Box>
        ) : (
          markDownFormat ? (
            <div style={{
              fontSize: "14px",
              padding: "5px",
              border: "1px solid #ccc",
              background: "#f2f2f2",
              borderRadius: "10px",
              maxHeight: "500px",
              overflow: "auto"
            }}>
              <Editor data={notes} editable={false} />
            </div>
          )
            : (
              <div style={{
                fontSize: "14px",
                padding: "5px",
                border: "1px solid #ccc",
                background: "#f2f2f2",
                borderRadius: "10px"
              }} dangerouslySetInnerHTML={{ __html: notes }} />
            )
        )
      }
    </Box>
  );
};

export default PastVisitModalComponent;
