import { Box, Grid, useTheme } from "@mui/material";
import { useFormik } from "formik";
import * as toast from "hooks/notify";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSendMessageData,
  sendMessageToEmail,
} from "redux/features/SendMessage/SendMessageSlice";
import { getGroupWiseUsers } from "utils/getGroupsWiseUsers";
import { sendMessageValidationSchema } from "validations/Modals/sendMessage.schema";
import Button from "../Button/Button";
import InputField from "../FormComponents/InputField";
import CustomModal from "./CustomModal";

const SendMessageModal = ({ open, setOpen }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { groupsOptions } = useSelector((state) => state.options);
  const { usersList } = useSelector((state) => state.MyTeams);
  const { isSendMessageLoading, sendMessageSuccess } = useSelector(
    (state) => state.sendMessage
  );

  const formik = useFormik({
    initialValues: {
      subject: "",
      message: "",
    },
    validationSchema: sendMessageValidationSchema,
    onSubmit: (values) => {
      values.to_email = getGroupWiseUsers(groupsOptions, usersList).filter(
        (res) => res.name === "Front Desk Representatives"
      )[0]?.data[0]?.email;
      dispatch(sendMessageToEmail({ ...values, recipient_type: "front_desk" }));
    },
  });
  useEffect(() => {
    return () => {
      dispatch(clearSendMessageData());
    };
  }, []);

  useEffect(() => {
    setOpen(false);
    sendMessageSuccess && toast.success("Message sent successfully");
    sendMessageSuccess === false && toast.error("Message not sent");
    dispatch(clearSendMessageData());
    formik.resetForm();
  }, [sendMessageSuccess]);

  useEffect(() => {
    if (open === false) {
      formik.resetForm();
    }
  }, [open]);

  return (
    <CustomModal
      setOpen={setOpen}
      open={open}
      title={"Send Message to front desk"}
      width="auto"
      maxWidth={"sm"}
      fullWidth={true}
    >
      <Grid container>
        <InputField
          label="Subject"
          formik={formik}
          name="subject"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          placeholder={"Subject goes here...."}
        />
        <InputField
          label="Message"
          formik={formik}
          name="message"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          multiline={true}
          rows={6}
          placeholder={"Please type your message here..."}
        />
        <Box
          sx={{
            display: "flex",
            paddingTop: "15px",
            justifyContent: "flex-end",
            flex: "1",
          }}
        >
          <Button
            isLoading={isSendMessageLoading}
            text="Send Message"
            data-testid={"sendMessage-modal-send-button"}
            backgroundColor={theme.palette.common.blue}
            btnColor={theme.palette.common.white}
            borderRadius="10px"
            fontSize="14px"
            fontWeight="600"
            onClick={formik.handleSubmit}
            sx={{ width: "150px" }}
          />
        </Box>
      </Grid>
    </CustomModal>
  );
};

export default SendMessageModal;
