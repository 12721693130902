import { apiGet, apiPost } from './base';

export const fetchAllFeedbacks = async () => {
    const url = `/api/feedback/`;
    const response = await apiGet(url);

    let next = response.data?.next;

    while (next) {
        const nextResponse = await apiGet(next);
        response.data?.results.push(...nextResponse.data?.results);
        next = nextResponse.data?.next;
    }

    return response.data?.results;
}

export const addFeedback = async (data) => {
    const url = `/api/feedback/`;
    const response = await apiPost(url, data);
    return response.data;
}