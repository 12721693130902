import { createSlice } from "@reduxjs/toolkit";

export const initialgetSayaState = {
  getSayaIsLoading: false,
  getSayaSuccess: null,
  getSayaData: [],
};

const name = "getSaya";

export const getSayaSlice = createSlice({
  name,
  initialState: initialgetSayaState,
  reducers: {
    getSaya: (state, action) => {
      state.getSayaIsLoading = true;
    },
    getSayaSuccess: (state, action) => {
      state.getSayaIsLoading = false;
      state.getSayaSuccess = true;
      state.getSayaData = action?.payload;
    },
    getSayaFail: (state, action) => {
      state.getSayaIsLoading = false;
      state.getSayaSuccess = false;
      state.getSayaData = [];
    },
    clearSayaData: (state, action) => {
      state.getSayaIsLoading = false;
      state.getSayaSuccess = null;
      state.getSayaData = [];
    },
  },
});

export const { getSaya, clearSayaData } = getSayaSlice.actions;

export default getSayaSlice.reducer;

export const getSayaResult = (patientId, data) => ({
  payload: {
    apiName: name,
    method: "post",
    data,
    url: patientId!==null ? `/api/patients/${patientId}/saya/`:`/api/saya/`,
  },
  type: "getSaya",
});
