import {
  alignItemsFlexStart,
  flexRow,
  flexWrap,
  justifyContentFlexEnd,
  justifyContentSpaceBetween,
} from "styles";

export let mainPrivacySecurityBox = {
  padding: "5px 15px 50px 15px",
  backgroundColor: "rgba(255, 255, 255, 0.75)",
};
export let upperPasswordAuthenticationBox = {
  backgroundColor: "#ffffff",
  borderRadius: "24px",
  height: "100%",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
};

export let changePasswordButtonBoxStyling = () => {
  return {
    padding: "15px 20px 20px 20px",
  };
};

export let twoFactorAuthBoxStyling = {
  backgroundColor: "#ffffff",
  borderRadius: "24px",
  height: "100%",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
};
export let twoFactorInnerBox = {
  ...flexRow,
  ...alignItemsFlexStart,
  padding: "10px 10px 0px 10px",
  opacity: 0.6,
};
export let twoFactorParagraph = {};
export let twoFactorTogglerBox = {
  marginTop: "5px",
};
