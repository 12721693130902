import { createSlice } from '@reduxjs/toolkit'

export const initialAllergiesState = {
    allergiesData: [],
    allergiesIsLoading: false,
}

const name = "allergies"

export const allergiesSlice = createSlice({
    name,
    initialState: initialAllergiesState,
    reducers: {
        getAllergies: (state, action) => {
            state.allergiesIsLoading = true
        },
        getAllergiesSuccess: (state, action) => {
            state.allergiesIsLoading = false
            state.allergiesData = action.payload?.results;
        },
        getAllergiesFail: (state, action) => {
            state.allergiesData = []
            state.allergiesIsLoading = false
        },
        clearAllergiesData: (state, action) => {
            state.allergiesData = []
            state.allergiesIsLoading = false
        }
    },
})

export const { getAllergies, clearAllergiesData } = allergiesSlice.actions

export default allergiesSlice.reducer

export const getAllergiesByPatientId = (patientId) => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/patients/${patientId}/allergies/`
    },
    type: "getAllergies",
});