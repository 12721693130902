
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import {  TRANSFORMERS } from "@lexical/markdown";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import {
  TableCellNode,
  TableNode,
  TableRowNode,
} from '@lexical/table';
import { forwardRef} from 'react';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import EditorTheme from './EditorTheme'
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { ListItemNode, ListNode } from '@lexical/list';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import ConversionsPlugin from './plugins/ConversionsPlugin';

const editorConfig = {
  namespace: 'React.js Demo',
  nodes: [
    HeadingNode,
    CodeHighlightNode,
    CodeNode,
    QuoteNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    ListNode,
    ListItemNode,
    AutoLinkNode,
    LinkNode
  ],
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // The editor theme
  theme: EditorTheme,
};

function Placeholder() {
  return <div className="editor-placeholder">No data</div>;
}

const Editor = forwardRef(({ data, editable= true }, ref) => {
  return (
    <LexicalComposer initialConfig={{...editorConfig, editable}}>
      <div className="editor-container">
        {editable ?  <ToolbarPlugin /> : null }
        <ConversionsPlugin data={data} ref={ref} />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <ListPlugin />
          <AutoFocusPlugin />
          <TablePlugin />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </div>
      </div>
    </LexicalComposer>
  );
})

export default Editor;
