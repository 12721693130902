import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  creatNewPasswordsIsLoading: false,
  creatNewPasswordsSuccess: false,
};

const name = "createNewPassword";

export const createNewSliceSlice = createSlice({
  name,
  initialState: initialState,
  reducers: {
    createNewPassword: (state, action) => {
      state.creatNewPasswordsIsLoading = true;
    },
    createNewPasswordSuccess: (state, action) => {
      state.creatNewPasswordsIsLoading = false;
      state.creatNewPasswordsSuccess = true;
    },
    createNewPasswordFail: (state, action) => {
      state.creatNewPasswordsIsLoading = false;
      state.creatNewPasswordsSuccess = false;
    },
    clearCreateNewPasswordData: (state, action) => {
      state.creatNewPasswordsIsLoading = false;
      state.creatNewPasswordsSuccess = false;
    },
  },
});

export const { createNewPassword, clearCreateNewPasswordData } =
  createNewSliceSlice.actions;

export default createNewSliceSlice.reducer;

export const createNewPasswordAPI = (body, hiddenToken, isReset) => ({
  payload: {
    apiName: name,
    method: "post",
    data: body,
    url:
      isReset == "true"
        ? `/api/reset-password/${hiddenToken}/`
        : `/api/change-password/${hiddenToken}/`,
  },
  type: "createNewPassword",
});

export const getCSRF = (query) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/csrf-token/`,
  },
  type: "getCSRF",
});
