import { LoadingButton } from "@mui/lab";
const Button = ({
  id = "",
  onClick,
  text,
  isLoading = false,
  disabled,
  children,
  backgroundColor = "",
  border,
  btnColor = "",
  borderRadius = "12px",
  padding = "",
  fontSize,
  fontWeight = "600",
  boxShadow,
  leftSide = false,
  rightSide = false,
  color,
  sx = {},
  iconDetails = { allowIcon: false, icon: null },
  ...props
}) => {
  let { allowIcon, icon } = iconDetails;

  return (
    <LoadingButton
      data-testid={id}
      sx={{
        backgroundColor: backgroundColor,
        border: border,
        color: btnColor,
        borderRadius: borderRadius,
        fontSize: fontSize,
        fontWeight: fontWeight,
        boxShadow: boxShadow,
        padding: padding,
        "&:hover": {
          backgroundColor: backgroundColor,
          color: btnColor,
        },
        ...sx,
      }}
      onClick={onClick}
      loading={isLoading}
      disabled={disabled || isLoading}
      {...props}
    >
      {leftSide && allowIcon ? (
        <img
          alt={icon}
          src={icon}
          style={{ padding: "0px 5px 0px 5px", height: "28px", width: "28px" }}
        />
      ) : (
        ""
      )}
      {text}
      {rightSide && allowIcon ? (
        <img
          alt={icon}
          src={icon}
          style={{ padding: "0px 5px 0px 5px", height: "28px", width: "28px" }}
        />
      ) : (
        ""
      )}
    </LoadingButton>
  );
};
export default Button;
