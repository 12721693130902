import { createSlice } from "@reduxjs/toolkit";

export const name = "resultsLabs";

export const initialLabsResultsStateValue = {
    isLabResultsLoading: false,
    labsResultsSuccess: null,
    labResults: [],
};

export const labResultsSlice = createSlice({
    name,
    initialState: initialLabsResultsStateValue,
    reducers: {
        labResults: (state) => {
            state.isLabResultsLoading = true;
        },
        labResultsSuccess: (state, action) => {
            state.isLabResultsLoading = false;
            state.labsResultsSuccess = true;
            state.labResults = action.payload?.labs;
        },
        labResultsFail: (state) => {
            state.isLabResultsLoading = false;
            state.labsResultsSuccess = false;
            state.labResults = [];
        },
        clearLabsResultsMessage: (state) => {
            state.message = "";
            state.isLabResultsLoading = false;
            state.labsResultsSuccess = null;
            state.labResults = [];
        },
    },
});

export const { labResultsSuccess, labResultsFail, clearLabsResultsMessage } = labResultsSlice.actions;

export default labResultsSlice.reducer;

export const getLabsResults = (patienId) => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/patient-labs/?patient_id=${patienId}`
    },
    type: "labResults",
});