import { Box, Tabs } from "@mui/material";
import {
  graphStaticApiFullFormValue,
  graphStaticColors,
} from "assets/static";
import CustomTabs from "components/common/Tabs/CustomTabs";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { trendsMainVitalsTabBoxStyle } from "styles/SCD/trendsStyle";
import { toCapitalize } from "utils/toCapitalize";

const VitalTrends = () => {

  const [date, setDate] = useState([]);

  // in order to keep track of active properties data
  const [activeVitalsData, setActiveVitalsData] = useState({})

  // in order to keep track of valid data for each vital (to avoid full empty data)
  const [vitalsValidData, setVitalsValidData] = useState({});


  // Sorted data of vitals according to the date_time
  const [sortedVitalsData, setSortedVitalsData] = useState([]);

  const { vitalsData } = useSelector((state) => state.vitals);

   /**
    * @param {Array[Numbers]} values - The value to be added .
    * @param {string} selectedValue - The value to be added .
    * Capitalize the unit and calculate weight difference
  */
  function getUnitLabel(values, selectedValue) {
    const unit = toCapitalize(graphStaticApiFullFormValue?.[selectedValue])
    const isWeight = graphStaticApiFullFormValue?.[selectedValue] === "weight";
    const difference = isWeight && values.length > 2 ?
      `Difference: ${values[values.length - 1] - values[values.length - 2]}` : ""
    return `${unit} ${difference}`
  }

  /**
  * @param {Array[String]} values - The value to be added .
  * removing active data, unit, color to the graph
  */
  function removeFromActiveGraph(values) {
    let newActiveVitalsData = { ...activeVitalsData };
    values.forEach((value) => delete newActiveVitalsData[value]);
    setActiveVitalsData(newActiveVitalsData);
  }

  /** 
   * @param {string} value - The value to be added .
   * Adding active data, unit, color to the graph
  */
  function addToGraph(value) {
    let vitalData = vitalsValidData?.[value];
    setActiveVitalsData((prev) => {
      return {
        ...prev,
        [value]: {
          data: vitalData,
          unit: getUnitLabel(vitalData, value),
          color: graphStaticColors[value]
        }
      }
    })
  }

  /**
   * Validates and stores the vitals data.
   * @param {boolean} checked - to check if checked or not.
   * @param {value} value - The value to be added/remove.
   * @returns {void}
   */
  function setActiveGraphs(checked, value) {
    if (vitalsData?.length > 0) {
      if (checked) {
        if (value === "BP") {
          addToGraph("BPS");
          addToGraph("BPD");
        }
         else addToGraph(value);
      } else {
        if (value === "BP") {
          removeFromActiveGraph(["BPS", "BPD"]);
        }
         else removeFromActiveGraph([value]);
      }
    }
  }

  /**
   * Validates and stores the vitals data.
   * @param {string} val - The value to be validated and stored.
   * @returns {void}
   */
  
  function validateAndStoreVitalsData(val){
      let vitalData = sortedVitalsData?.map((s) => Number(s?.[graphStaticApiFullFormValue?.[val]]))
      let isNotValidVitalsData = vitalData.every(num => num === 0);
      if(!isNotValidVitalsData){
        setVitalsValidData((prev) => {
          return {...prev, [val]: vitalData};
        });
      }
  }

  // To set the date once data is recieved or changed
  useEffect(() => {
    if (vitalsData?.length > 0) {

      // Sorted the vitals data according to the date_time
      const sortedVitalsData = [...vitalsData].sort(
        (a, b) => moment(a.date_time) - moment(b.date_time)
      );
      setSortedVitalsData(sortedVitalsData)

      // Sort the formatted dates from oldest to latest
      const sortedDates = sortedVitalsData.map((data) => moment(data.date_time).format("YYYY-MM-DD"));
      setDate(sortedDates);

    }
  }, [vitalsData]);

  useEffect(() => {

    // Calculate all vitals data and check if they are valid (all empty or not) and keep them in state
    Object.keys(graphStaticApiFullFormValue).map((val) => validateAndStoreVitalsData(val))
  }, [sortedVitalsData])

  useEffect(() => {
    const firstValidVitalKey = Object.keys(vitalsValidData)[0];
    if (firstValidVitalKey) {
      if (firstValidVitalKey === "BPS" || firstValidVitalKey === "BPD") {
        addToGraph("BPD");
        addToGraph("BPS");
      }
      else addToGraph(firstValidVitalKey);
    }
  }, [vitalsValidData])

  let graphData = {
    series: activeVitalsData ? Object.keys(activeVitalsData).map((s) => ({ name: activeVitalsData[s].unit, data: activeVitalsData[s].data })) : [],
    options: {
      colors: activeVitalsData ? Object.keys(activeVitalsData).map((s) => activeVitalsData[s].color) : [],
      chart: {
        height: 250,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: date,
      },
      yaxis: {
        min: 0,
        max: Math.max(...Object.values(activeVitalsData).flatMap((vital) => vital.data)) + 20,
        labels: {
          formatter: function (val) {
            return Math.round(val);
          },
        },
        tickAmount: 4,
      },
    tooltip: {
      enabled: true,
      shared: true,
      y: {
        title: {
            /** 
            * @param {string} val - The label that is being shown in the tooltip .
            * This function will convert the weight difference label to "Weight" to avoid the confusion.
            */
          formatter: function(val) {
            if ((val).startsWith("Weight")) return "Weight:";
            else return `${val}:`;
          }
        },
      }
    }
    },
  };


  return (
    vitalsData?.length > 0 ?
    <Box
      data-testid="vital-trends-id"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginLeft: "10px",
      }}
    >
        <Tabs
          variant="scrollable"
          sx={trendsMainVitalsTabBoxStyle}
          indicatorColor="red"
          orientation="vertical"
        >
          <Box sx={{ 
            marginTop: "20px",
            display:"flex",
            flexDirection:"column",
            justifyContent:"space-between",
           }}>
          {
            Object.keys(vitalsValidData).map((s, i) => {
              if(s === "BPD") return null ;
              return (
                <CustomTabs
                  key={i}
                  defaultChecked={i === 0}
                  label={s === "BPS"? "BP": s}
                  setActiveGraphs={setActiveGraphs}
                />
              )})
          }
          </Box>
        </Tabs>
      <Box data-testid="vitals-trends-id" sx={{ width: "82%" }}>
        <Box id={"chart"}>
          <ReactApexChart
            options={graphData.options}
            series={graphData.series}
            type="line"
            height={340}
          />
        </Box>
      </Box>
    </Box>: 
    (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // adjust if needed for full-height centering
          minHeight: '250px', // optional for minimum height
        }}
      >
        No Data Found
      </Box>
  )
  );
};
export default memo(VitalTrends);
