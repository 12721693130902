import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addFeedback, fetchAllFeedbacks } from "apiClients/feedback";
import { useEffect } from "react";

export const useFeedback = () => {
    const queryClient = useQueryClient();

    const mutationFeedback = useMutation({
        mutationFn: async (payload) => {
            return addFeedback(payload);
        },
        onSuccess: (newFeedback) => {
            // Update the cache directly without invalidating
            queryClient.setQueryData(["feedbacks"], (oldFeedbacks = []) => { return [...oldFeedbacks, newFeedback]; });
        },
    });

    const { data: feedbackList, isLoading: isFetching } = useQuery({
        queryKey: ["feedbacks"],
        staleTime: 10 * 60 * 1000, // 10 minutes stale time
        queryFn: async () => await fetchAllFeedbacks(),
    });

    useEffect(() => {
        return () => {
            queryClient.removeQueries({
                queryKey: ["feedbacks"]
            });
        }
    }, [queryClient]);

    return { feedbackList, isFetching, mutationFeedback }

};