import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import profileAvatar from "assets/images/profileAvatar.png";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles({

  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "80px",
    background:"red",
    height: "80px",
    maxWidth: "80px",
    cursor: "pointer",
    maxHeight: "80px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    borderRadius: "100%",
    justifyContent: "center",
    color: "#fff",
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
    "&:hover": {
      opacity: 1,
      cursor: "pointer",
      borderRadius: "100%",
    },
  },
});


const imageStyles = {
  position: 'relative',
  maxWidth: "100%",
  maxHeight: "150px",
  height: "80px",
  width: "80px",
  backgroundSize: "contain",
  objectFit: "cover",
  objectPosition: "50% 0",
  borderRadius: "10px",
};

const ImageDropzone = ({ formik, name, inForm = true }) => {
  const classes = useStyles();
  let theme = useTheme();
  const [selectedImage, setSelectedImage] = useState(formik?.values?.[name]);
  const [error, setError] = useState(null);

  const dropzoneStyles = {
    position: 'relative',
    display: "flex",
    flexDirection: "column",
    height: "80px",
    width: "80px",
    justifyContent: "center",
    alignItems: "center",
    border: `2px solid ${theme?.palette?.common?.grey}`,
    borderRadius: "100%",
    padding: "10px",
    textAlign: "center",
    cursor: "pointer",
  };
  
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/bmp"];
    if (file && allowedTypes.includes(file?.type)) {
      setError(null);

      formik?.setFieldValue(name, file);

      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setError(
        "Unable to upload. Please upload a PNG, JPEG, JPG, or BMP file."
      );
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
      "image/bmp": [],
    },
  });

  useEffect(() => {
    setSelectedImage(formik?.values?.[name]);
  }, [formik?.values?.[name]]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: inForm ? '' : "center",
        justifyContent: "center",
        margin: "10px 0px",
      }}
      data-testid={name}
    >
      <div style={selectedImage ? imageStyles : dropzoneStyles}>
        <img
          alt="User Pic"
          src={selectedImage ? selectedImage : profileAvatar}
          id="profile-image1"
          height="100"
          style={imageStyles}
        />

        <div {...getRootProps()} className={classes.overlay}>
          <input {...getInputProps()} />
          <p>Update</p>
        </div>
      </div>
      {
        error
          ? error && <small style={{ color: "red", marginTop: "5px" }}>{error}</small>
          : <small style={{ margin: "6px 6px" }}>Upload Image</small>
      }
    </div>
  );
};

export default ImageDropzone;
