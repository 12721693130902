import { Divider } from "@mui/material";
import { memo, useContext, useEffect, useState } from "react";
import Text from "components/common/Typography/Text";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addMedication, deleteMedication, fetchAllMedications, updateMedication } from "apiClients/medications";
import moment from "moment";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import { removeEmptyValuesFromObject } from "../common/utils";
import { SubForm } from "../common/SubForm";
import { MedicationSelectInput } from "components/common/FormComponents/MedicationSelectInput";

export const MedicationsReview = memo(() => {
    const queryClient = useQueryClient();
    const [mappedResults, setMappedResults] = useState([]);
    const { registerForm, patientId } = useContext(IntakeFormContext);

    const medicationsInputs = [
        {
            label: "Name",
            key: "drug_name",
            customComponent: ({ key, ...rest }) => <MedicationSelectInput key={key} {...rest} />,
        },
        {
            label: "Dosage",
            key: "dosage",
            cols: 3,
        },
        {
            label: "Frequency",
            key: "frequency",
            cols: 3,
        },
        { type: "spacer", cols: 6 },
        {
            label: "Start Date",
            key: "start_date",
            date: true,
            cols: 3,
        },
        {
            label: "End Date",
            key: "end_date",
            date: true,
            cols: 3,
        },
    ];

    const mutation = useMutation({
        mutationFn: async (dirtyValue) => {
            if (dirtyValue.id) {
                if (dirtyValue.delete) {
                    // If delete is set we need to delete the medication.
                    return deleteMedication(patientId, dirtyValue.id);
                } else {
                    // If no delete is set, we need to update the medication since it is dirty (changed).
                    return updateMedication(patientId, dirtyValue.id, dirtyValue);
                }
            } else {
                // If no ID is present, it is a new medication and we need to add it.
                return addMedication(patientId, dirtyValue);
            }
        },

        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["medications", patientId]
            });
        },
    });

    useEffect(() => {
        return () => {
            queryClient.resetQueries({
                queryKey: ["medications", patientId]
            });
        }
    }, [queryClient, patientId]);

    const { data, isLoading } = useQuery({
        queryKey: ["medications", patientId],
        queryFn: async () => await fetchAllMedications(patientId),
    });

    const mapResults = ((results = []) => {
        const getDate = (date) => date ? moment(date).format("YYYY-MM-DD") : '';
        const extractFn = (amount, unit) => {
            if (!amount) return '';
            return `${amount}${unit ? ` ${unit}` : ''}`;
        }
        return results.map(result => {
            const dosage = extractFn(result.dosage_quantity_value, result.dosage_quantity_unit);
            const frequency = extractFn(result.dosage_period, result.dosage_period_unit);

            return {
                ...result,
                dosage,
                frequency,
                start_date: getDate(result.start_date),
                end_date: getDate(result.end_date),
            }
        });
    });

    const handleSubmit = async (dirtyValues) => {
        const operations = dirtyValues.map((dirtyValue) => {
            if (dirtyValue.delete) {
                return mutation.mutateAsync(dirtyValue);
            };

            // Only map when not deleting
            const [dosage_quantity_value, dosage_quantity_unit] = dirtyValue.dosage.split(' ');
            const [dosage_period, dosage_period_unit] = dirtyValue.frequency.split(' ');

            const mappedValue = {
                ...dirtyValue,
                dosage_quantity_value,
                dosage_quantity_unit,
                dosage_period,
                dosage_period_unit,
            }
            return mutation.mutateAsync(removeEmptyValuesFromObject(mappedValue));
        });
        return Promise.allSettled(operations);
    }

    useEffect(() => {
        const val = mapResults(data?.results);
        registerForm('medications', handleSubmit, val);
        setMappedResults(val);
    }, [data]);

    return <>

        <Text variant="h1">Medications Review</Text>

        <Divider sx={{ my: 2 }} />

        <SubForm subSections={medicationsInputs} formKey="medications" dataLoading={isLoading} data={mappedResults} />
    </>
});