import { Grid } from "@mui/material";
import Text from "components/common/Typography/Text";
import { memo } from "react";

export const SubFormTitle = memo(({ title, tag = 'h5', sx = {} }) => {
    return <Grid container sx={{ justifyContent: 'space-between', marginTop: '1em', height: "40px", ...sx }}>
        <Grid item sm={6} sx={{display: 'flex', alignItems: 'center'}}>
            <Text variant={tag}>{title}</Text>
        </Grid>
    </Grid>
})