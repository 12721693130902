export const debounce = (func, delay) => {
    let timeoutId;
    const debounced = function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
    debounced.cancel = () => {
        clearTimeout(timeoutId);
    };
    return debounced;
};

export const DEBOUNCE_DELAY = 700;