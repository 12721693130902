import { Box } from "@mui/material";
import { sortWeights } from "assets/static";
import Text from "components/common/Typography/Text"
import { useEffect, useState } from "react";
import { checkNullOrUndefinedString, getFormattedLabs } from "utils/helper";

const LabResultDetailCard = ({
  data,
  marginTop,
  pastVisit,
}) => {
  const [formattedLabsData, setFormattedLabsData] = useState([]);

  useEffect(() => {
    setFormattedLabsData(getFormattedLabs(data))
  }, [data])

  return (
    <Box sx={{ marginTop: marginTop }}>
      {data !== null ? (
        <>
          {!pastVisit ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  data-testid="labresults-heading"
                  sx={{
                    color: "#2D3748",
                    fontSize: "20px",
                    lineHeight: "28.79px",
                    fontWeight: "700",
                  }}
                >
                  <span style={{ borderBottom: "2px solid  #1344F1" }}>
                    Lab Results
                  </span>
                </Box>
              </Box>
            </>
          ) : (
            ""
          )}
          <Box
            sx={{
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <Box
              data-testid="labresults-tests-details"
              sx={{ maxHeight: "490px", marginTop: "10px", overflowY: "auto" }}
            >
              <Box>
                {formattedLabsData?.map(
                  (
                    {
                      name,
                      value,
                      unit,
                      reference_range,
                      in_range
                    },
                    j
                  ) => (
                    <Box key={j} marginY={1}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Text
                          data-testid={`lab-test-name-${j}`}
                          variant="h3"
                          minWidth="160px"
                        >
                          {name ?? ""}
                        </Text>
                        <Text
                          variant="bodyXs"
                          marginLeft="20px"
                          data-testid={`lab-value-${j}`}
                          color={in_range === "normal" ? "" : "red"}
                        > {checkNullOrUndefinedString(
                          Array.isArray(value) && value.length > 1
                            ? `${value.join('-')}`
                            : value
                        )} {unit}
                        </Text>
                      </Box>

                      <Text variant="formLabel">
                        Ref:
                        <Text variant="formLabel" data-testid={`lab-range-${j}`}>
                          &nbsp; {reference_range ?? ""} {unit}
                        </Text>
                      </Text>
                    </Box>
                  )
                )
                }
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box marginY={4}>
          <Text variant="bodyS">No Data Found.</Text>
        </Box>
      )}
    </Box>
  );
};

export default LabResultDetailCard;
