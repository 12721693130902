import CustomModal from "components/common/Modal/CustomModal";
import { RecentMedicalEncounters } from "./sections/RecentMedicalEncounters";
import { Fragment, useContext, useState } from "react";
import { MedicationsReview } from "./sections/MedicationsReview";
import { AllergyReview } from "./sections/AllergyReview";
import { LabsReview } from "./sections/LabsReview";
import { Box } from "@mui/material";
import Button from "components/common/Button/Button";
import { useTheme } from "@emotion/react";
import { InsuranceReview } from "./sections/InsuranceReview";
import { IntakeFormContext } from "./common/IntakeFormProvider";
import { getDirtyValues } from "./common/utils";
import Text from "components/common/Typography/Text";

import './styles.css';
import { PharmacyReview } from "./sections/PharmacyReview";

const errorSectionMap = {
    medicalEncounters: "Recent Medical Encounters",
    medications: "Medications",
    allergies: "Allergies",
    medicalReports: "Medical Reports",
    insurance: "Insurance",
    consent: "Consent",
}

export const PatientIntakeFormModal = ({ open, setOpen }) => {
    const theme = useTheme();
    const initialFormValues = {
        pharmacies: [],
        medicalEncounters: [],
        medications: [],
        allergies: [],
        medicalReports: [],
        insurance: {
            payer_name: "",
            policy_number: "",
            group_number: "",
            guarantor_address: "",
            guarantor_birthdate: "",
            guarantor_city: "",
            guarantor_first_name: "",
            guarantor_last_name: "",
            guarantor_phone_number: "",
            guarantor_social_security_number: "",
            guarantor_state: "",
            guarantor_zipcode: "",
            secondaries: [],
        },
        consent: {},
    }

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const { initialValues, handlers, mappers, patientId } = useContext(IntakeFormContext);
    const sections = [
        { component: RecentMedicalEncounters, },
        { component: MedicationsReview, },
        { component: AllergyReview, },
        { component: LabsReview, },
        { component: PharmacyReview },
        { component: InsuranceReview },
    ];

    // This function will transform the flat form data into a nested object.
    function transformToNestedObject(input) {
        const output = initialFormValues;

        for (const key in input) {
            // Split by both [] and . and filter out empty values
            const keys = key.split(/[[\].]+/).filter(Boolean);

            // Start at the root of the default values object.
            let current = output;

            for (let i = 0; i < keys.length; i++) {
                const keyPart = keys[i];
                if (i === keys.length - 1) {
                    // If we are at the last key, we can set the value.
                    current[keyPart] = input[key];
                } else {
                    if (!current[keyPart]) {
                        // If the key does not exist, we need to create an object or array depending on the next key. If the next key is a number, we create an array. otherwise, we create an object.
                        current[keyPart] = isNaN(keys[i + 1]) ? {} : [];
                    }
                    // Move to the next key.
                    current = current[keyPart];
                }
            }
        }

        return output;
    }

    function close() {
        setOpen(false);
        setErrors({});
    }

    function getFormattedErrors(errors) {
        if (!errors || Object.keys(errors).length === 0) {
            return null;
        }

        return <>
            <section className="patient-intake-form-errors-section">
                <Text> Errors: </Text>
                {Object.entries(errors).map(([key, value]) => {
                    const errors = Object.values(value);
                    const keyName = `${errorSectionMap[key] || key}`;
                    return <>
                        <Text variant="body1">{keyName}</Text>
                        {errors.map((error, idx) => {
                            if (!error?.reason?.response) {
                                return keyName;
                            } else {
                                const response = error?.reason?.response;
                                return (
                                    <Fragment key={idx}>
                                        <ul>
                                            {response && Object.entries(response.data).length > 0 && (
                                                Object.entries(response.data).map(([errorKey, errorMessage]) => (
                                                    <li key={errorKey}>{errorKey}: {errorMessage}</li>
                                                ))
                                            )}
                                        </ul>
                                    </Fragment>
                                );
                            }
                        })}
                    </>
                })}
            </section >
        </>
    }

    async function handleSubmit(values) {
        const errorMap = {};
        const dirtyValues = getDirtyValues(values, initialValues, mappers);
        setLoading(true);
        setErrors({});
        for (const [key, value] of Object.entries(dirtyValues)) {
            if (handlers[key]) {
                // Handlers for forms should use allSettled to ensure all forms are submitted.
                const results = await handlers[key](value);

                const errors = results.filter((result) => result.status === "rejected");

                if (errors.length) {
                    errorMap[key] = errors;
                }
            }
        }

        setLoading(false);
        if (Object.keys(errorMap).length === 0) {
            setOpen(false);
        } else {
            setErrors(errorMap);
        }
    }

    return <>
        <CustomModal
            setOpen={close}
            open={open}
            title={"Patient Intake Form"}
            maxWidth={"lg"}
            fullWidth={true}
            fade={true}
        >
            <form onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const formValues = Object.fromEntries(formData.entries());
                handleSubmit(transformToNestedObject(formValues));
            }}>
                <>
                    <ol>
                        {sections.map((section, index) => {
                            const Component = section.component;
                            return <Fragment key={index}>
                                <li style={{ "fontSize": "24px" }}><Box sx={{ marginBottom: "12px" }}><Component patientId={patientId} /></Box></li>
                            </Fragment>
                        })}
                    </ol>
                    {getFormattedErrors(errors)}
                    <Box
                        sx={{
                            display: "flex",
                            padding: "25px 0px 0px 10px",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            borderRadius="10px"
                            border={"1px solid #555555"}
                            onClick={close}
                            text="cancel"
                            sx={{
                                width: "160px",
                                backgroundColor: theme.palette.common.white,
                                color: theme.palette.common.black,
                                marginRight: "15px",
                            }}
                        />
                        <Button
                            variant={"contained"}
                            borderRadius="10px"
                            btnColor={theme.palette.common.white}
                            backgroundColor={theme.palette.common.blue}
                            isLoading={loading}
                            text="Save"
                            type="submit"
                            sx={{
                                width: "160px",
                            }}
                        />
                    </Box>
                </>
            </form>
        </CustomModal>
    </>
};