export const isOutOfRange = (val, range) => {
  const ranges = range?.split(" ")?.[0]?.split("-");
  let isOutRange;
  if (range == "negative") {
    isOutRange = false;
  } else {
    isOutRange =
      val?.split(" ")?.[0] >= ranges?.[0] && val?.split(" ")?.[0] <= ranges?.[1]
        ? false
        : true;
  }
  return isOutRange;
};
