import moment from "moment";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const DatePickerInput = ({
  isUtc,
  value,
  setDate,
  defaultDate,
  onChangeFunc,
  border = "",
  minWidth = "160px !important",
  maxWidth = "188px !important",
  borderRadius = "10px",
  fontWeight = "700",
  disablePast = false,
  disableFuture = false,
  datePickerStyling,
  dateFormate = "YYYY-MM-DD",
}) => {
  const theme = useTheme();
  const useStyle = makeStyles({
    root: {
      "& .MuiStack-root": {
        overflow: "hidden",
        paddingTop: "0px !important",
      },
    },
    datePicker: {
      "& input": {
        fontWeight: fontWeight,
        fontSize: "15px",
        color: theme?.palette?.themeBlue?.primary,
      },
      "& .MuiInputBase-root.MuiOutlinedInput-root": datePickerStyling,
    },
  });

  const classes = useStyle();
  return (
    <Box className={classes.root}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DemoContainer components={["DatePicker", "DatePicker"]}>
          <DatePicker
            format={dateFormate}
            views={["year", "month", "day"]}
            value={value}
            disablePast={disablePast}
            defaultValue={defaultDate}
            disableFuture={disableFuture}
            className={classes.datePicker}
            onChange={(newValue) => {
              isUtc
                ? setDate(moment(newValue).utc())
                : setDate(moment(newValue).format("YYYY-MM-DD"));
              onChangeFunc &&
                onChangeFunc(moment(newValue).format("YYYY-MM-DD"));
            }}
            sx={{
              fontWeight: "700",
              border: border,
              borderRadius: borderRadius,
              minWidth: minWidth,
              maxWidth: maxWidth,
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};
export default DatePickerInput;
