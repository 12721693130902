import { createSlice } from '@reduxjs/toolkit'

const initialAppointmentState = {
    isAppointmentLoading: false,
    appointmentData: {},
}

const name = "specificAppointment"

export const specificAppointmentSlice = createSlice({
    name,
    initialState: initialAppointmentState,
    reducers: {
        getAppointment: (state, action) => {
            state.isAppointmentLoading = true
        },
        getAppointmentSuccess: (state, action) => {
            state.isAppointmentLoading = false
            state.appointmentData = action.payload
        },
        getAppointmentFail: (state, action) => {
            state.isAppointmentLoading = false
            state.appointmentData = {} 
        },
        clearAppointmentData: (state, action) => {
            state.isAppointmentLoading = false;
            state.appointmentData = {}
        }
    },
})

export const { getAppointment, clearAppointmentData } = specificAppointmentSlice.actions

export default specificAppointmentSlice.reducer

export const getSpecificAppointmentDetails = (appointmentId) => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/appointments/${appointmentId}/`,
    },
    type: "getAppointment",
});