import { alignItemsFlexEnd, flexRow, flexRowCenterCenter } from "styles";

export let appBar = {
  ...flexRow,
  ...alignItemsFlexEnd,
  width: "100%",
  height: "75px",
  boxShadow: "none",
  background: `linear-gradient(
    116.41deg,
    #eff4fb 16.09%,
    #f7f3ff 44.79%,
    #eff4fb 59.94%,
    #f7f4ff 95.81%
  ) !important`,
  borderRadius: "0px",
};
export let mainNavbar = (theme) => {
  return {
    height: "68px",
    width: "100%",
    marginLeft: "5px",
    marginRight: "5px",
    borderRadius: "15px 15px 15px 15px",
    backgroundColor: "transparent !important",
  };
};
export let logoAdminName = (theme) => {
  return {
    ...flexRowCenterCenter,
    fontSize: "32px",
    fontWeight: "900",
    marginLeft: "7px",
    color: theme.palette.common.white,
  };
};

export let mainNavbarInnerBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
};
export let navbarLocationsBox = (viewWidth) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: viewWidth > 992 ? "400px" : "auto",
    color: "#222222",
  };
};
export let navbarSearchIcon = {
  backgroundColor: "#eeeeee",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px",
  borderRadius: "50%",
};
