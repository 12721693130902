import { sortWeights } from "assets/static";
import moment from "moment";

// Function to check for null or undefined strings and format them
export const checkNullOrUndefinedString = (str) => {
  if (str == null || str === undefined) {
    return "";
  } else {
    // Ensure str is a string before performing replacements
    str = String(str).replace(/null/gi, "").replace(/undefined/gi, "");

    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};

// Function to calculate age from date of birth using moment.js
export const calculateAge = (dob) => {
  if (!dob) return "";
  return moment().diff(moment(dob), 'years');
};

export const getFormattedLabs = (data) => {
  let formattedData = [];
  data?.lab_tests?.map((labTests) => {
    labTests?.lab_observations.map(({
      name,
      value,
      unit,
      reference_range,
      in_range
    }) => {
      formattedData.push({
        name,
        value,
        unit,
        reference_range,
        in_range
      });
    });
  });

  // Sort formattedData according to sortWeights and prioritize eGFR values
  formattedData.sort((a, b) => {
    const defaultWeight = Object.keys(sortWeights).length;
    const indexA = sortWeights[a.name] ?? defaultWeight;
    const indexB = sortWeights[b.name] ?? defaultWeight;
    // Prioritize eGFR values
    if (a.name.startsWith("eGFR") && !b.name.startsWith("eGFR")) {
      return -1;
    }
    if (!a.name.startsWith("eGFR") && b.name.startsWith("eGFR")) {
      return 1;
    }
    return indexA - indexB;
  });

  return formattedData;
};