import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

const initialState = {
    isAdminLoading: false,
    isAdminUpdateLoading: false,
    adminData: {},
    adminUpdateSuccess: false
}

const name = "admin"

export const adminSlice = createSlice({
    name,
    initialState,
    reducers: {
        getAdmin: (state, action) => {
            state.isAdminLoading = true
        },
        updateAdminInfo: (state, action) => {
            state.isAdminUpdateLoading = true
        },
        updateAdminInfoSuccess: (state, action) => {
            state.isAdminUpdateLoading = false
            state.adminUpdateSuccess = false
            state.adminUpdateSuccess = true
        },
        updateAdminInfoFail: (state, action) => {
            state.isAdminUpdateLoading = false
            state.adminUpdateSuccess = false
            action.payload && toast.error("Request failed with status 400(Bad Request)")
        },
        getAdminSuccess: (state, action) => {
            state.isAdminLoading = false
            state.adminUpdateSuccess = false
            state.adminData = action.payload
        },
        getAdminFail: (state, action) => {
            state.isAdminLoading = false
            state.adminData = {}
        },
        softClearUpdateStatuses: (state) => {
            state.isAdminUpdateLoading = false
            state.adminUpdateSuccess = false
        },
        clearAdminStatuses: (state) => {
            state.isAdminLoading = false
            state.isAdminUpdateLoading = false
            state.adminUpdateSuccess = false
            state.adminData = {}
        }
    },
})

export const { getAdmin, clearAdminStatuses, softClearUpdateStatuses } = adminSlice.actions

export default adminSlice.reducer

export const getAdminInfo = (userId) => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/users/${userId}/`
    },
    type: "getAdmin",
});

export const updateAdminInfo = (userId, data) => ({
    payload: {
        apiName: name,
        data,
        formData: true,
        method: "PATCH",
        url: `/api/users/${userId}/`
    },
    type: "updateAdminInfo",
});