export const getMainContainerBoxWidth = (theme, pathname) => {
  return {
    width:
      pathname === "/" || pathname === "/schedule"
        ? {
            xs: "80%",
            sm: "86%",
            md: "87%",
            lg: "89%",
            xl: "92%",
            xxl: "94%",
          }
        : "100%",
    marginRight: "20px",
  };
};
